import { GET_ONE, GET_MANY } from 'react-admin';
import { countBy } from 'lodash';
import api from './api';

export default async (type, params) => {
  switch (type) {
    case GET_ONE: {
      const { id } = params;

      const response = await api.get('/support/db/test-takers', {
        params: {
          offset: 0,
          limit: 0,
          'job_opening_id.eq': id,
          'is_demo.eq': false,
        },
      });

      const { total } = response.data;

      return { data: { id, test_takers: total } };
    }

    case GET_MANY: {
      const { ids } = params;

      const response = await api.get('/support/db/test-takers', {
        params: {
          offset: 0,
          'job_opening_id.in': ids.join(','),
          'is_demo.eq': 'false',
        },
      });

      const { data } = response.data;

      const counts = countBy(data, 'job_opening_id');
      const records = ids.map(id => ({ id, test_takers: counts[id] || 0 }));

      return { data: records };
    }

    default:
      throw new Error(`Method not supported: ${type}`);
  }
};
