import React from 'react';
import get from 'lodash/get';
import JSONTree from 'react-json-tree';

export const JSONField = ({ record, source }) => {
  const value = get(record, source);
  return (
    <JSONTree
      data={value}
      theme={{
        tree: {
          padding: '10px',
          marginTop: 0,
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
        },
      }}
    />
  );
};

JSONField.defaultProps = {
  addLabel: true,
};

export default JSONField;
