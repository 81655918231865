import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import BuildIcon from '@material-ui/icons/Build';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import FeedbackIcon from '@material-ui/icons/Feedback';
import AccountBox from '@material-ui/icons/AccountBox';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';
import SupervisedUserCircleSharp from '@material-ui/icons/SupervisedUserCircleSharp';
import LiveHelp from '@material-ui/icons/LiveHelp';
import List from '@material-ui/icons/List';
import ListAlt from '@material-ui/icons/ListAlt';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import EmailIcon from '@material-ui/icons/Email';
import authProvider from '../../api/authProvider';
import dataProvider from '../../api/dataProvider';
import { supportAuthSaga } from '../../sagas';
import { WorkspaceList, WorkspaceShow } from '../workspaces';
import { CandidateList } from '../candidates';
import {
  QuestionList,
  QuestionCreate,
  QuestionEdit,
  QuestionShow,
} from '../questions';
import NotificationSettingsCreate from '../notification_settings/NotificationSettingsCreate';
import { SkillEdit, SkillList, SkillShow, SkillCreate } from '../skills';
import {
  PositionList,
  PositionShow,
  PositionEdit,
  PositionCreate,
} from '../positions';
import { PositionNameCreate, PositionNameEdit } from '../position_names';
import {
  JobOpeningList,
  JobOpeningShow,
  JobOpeningEdit,
} from '../job_openings';
import CandidateShow from '../candidates/CandidateShow';
import AdminFeedbackList from '../admin_feedback/AdminFeedbackList';
import CandidateFeedbackList from '../candidate_feedback/CandidateFeedbackList';
import { TalentPoolList, TalentPoolShow } from '../talent_pool';
import { UserList, UserShow } from '../users';
import {
  SubmittedQuestionEdit,
  SubmittedQuestionList,
  SubmittedQuestionShow,
} from '../submitted_questions';
import { JobOpeningVisitorShow } from '../job_opening_visitors';
import theme from './theme';
import { RequestList, RequestShow } from '../requests';
import {
  PositionTypeCreate,
  PositionTypeEdit,
  PositionTypeList,
  PositionTypeShow,
} from '../position_types';
import {
  PositionCategoryCreate,
  PositionCategoryEdit,
  PositionCategoryList,
  PositionCategoryShow,
} from '../position_categories';
import {
  SkillTypeCreate,
  SkillTypeEdit,
  SkillTypeList,
  SkillTypeShow,
} from '../skill_types';
import {
  SkillCategoryCreate,
  SkillCategoryEdit,
  SkillCategoryList,
  SkillCategoryShow,
} from '../skill_categories';
import { ExpertList, ExpertShow } from '../experts';
import { ExpertQuestionBatchList } from '../expert_question_batches';
import { ExpertQuestionList } from '../expert_questions';
import { AdminUserCreate, AdminUserList } from '../admin_users';
import {
  EmailTemplatesList,
  EmailTemplatesShow,
  EmailTemplatesEdit,
  EmailTemplatesCreate,
} from '../email_templates';
import { GoogleReCaptcha } from '../google_recaptcha';
import { fetchMe } from '../../api/authentication';

export const App = () => {
  const [me, setMe] = useState(null);

  useEffect(() => {
    async function fetchMeData() {
      try {
        const me = await fetchMe();
        setMe(me);
      } catch (error) {
        return null;
      }
    }
    fetchMeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return me || process.env.REACT_APP_SHARED_AUTH_ENABLED !== 'true' ? (
    <>
      <Admin
        title="Toggl Hire"
        authProvider={authProvider}
        dataProvider={dataProvider}
        customSagas={[supportAuthSaga]}
        theme={theme}
      >
        <Resource
          name="workspaces"
          list={WorkspaceList}
          show={WorkspaceShow}
          icon={BusinessIcon}
        />
        <Resource
          name="users"
          list={UserList}
          show={UserShow}
          icon={AccountBox}
        />
        <Resource
          name="candidates"
          list={CandidateList}
          show={CandidateShow}
          icon={PersonIcon}
        />
        <Resource
          name="job_openings"
          list={JobOpeningList}
          show={JobOpeningShow}
          edit={JobOpeningEdit}
          icon={PersonAddIcon}
        />
        <Resource name="job_opening_stats" />
        <Resource
          name="questions"
          list={QuestionList}
          create={QuestionCreate}
          edit={QuestionEdit}
          show={QuestionShow}
        />
        <Resource
          name="notification_settings"
          show={NotificationSettingsCreate}
          create={NotificationSettingsCreate}
        />
        <Resource name="workspace_user_jt" />
        <Resource
          name="test_gen_skills"
          options={{ label: 'Skills' }}
          list={SkillList}
          show={SkillShow}
          edit={SkillEdit}
          create={SkillCreate}
          icon={BuildIcon}
        />
        <Resource
          name="test_gen_skill_types"
          options={{ label: 'Skill Types' }}
          list={SkillTypeList}
          show={SkillTypeShow}
          edit={SkillTypeEdit}
          create={SkillTypeCreate}
          icon={BuildIcon}
        />
        <Resource
          name="test_gen_skill_categories"
          options={{ label: 'Skill Categories' }}
          list={SkillCategoryList}
          show={SkillCategoryShow}
          edit={SkillCategoryEdit}
          create={SkillCategoryCreate}
          icon={BuildIcon}
        />
        <Resource
          name="test_gen_positions"
          options={{ label: 'Positions' }}
          list={PositionList}
          show={PositionShow}
          edit={PositionEdit}
          create={PositionCreate}
          icon={FolderSharedIcon}
        />
        <Resource
          name="test_gen_position_types"
          options={{ label: 'Position Types' }}
          list={PositionTypeList}
          show={PositionTypeShow}
          edit={PositionTypeEdit}
          create={PositionTypeCreate}
          icon={FolderSharedIcon}
        />
        <Resource
          name="test_gen_position_categories"
          options={{ label: 'Position Categories' }}
          list={PositionCategoryList}
          show={PositionCategoryShow}
          edit={PositionCategoryEdit}
          create={PositionCategoryCreate}
          icon={FolderSharedIcon}
        />
        <Resource
          name="test_gen_position_names"
          create={PositionNameCreate}
          edit={PositionNameEdit}
        />
        <Resource name="test_gen_position_test_gen_skill_jt" />
        <Resource name="facebook_campaigns" />
        <Resource name="tests" />
        <Resource name="test_versions" />
        <Resource name="test_gen_skill_test_jt" />
        <Resource
          name="admin_feedback"
          list={AdminFeedbackList}
          options={{ label: 'Admin feedback' }}
          icon={FeedbackIcon}
        />
        <Resource
          name="candidate_feedback"
          list={CandidateFeedbackList}
          options={{ label: 'Candidate feedback' }}
          icon={FeedbackIcon}
        />
        <Resource
          name="talent_pool"
          list={TalentPoolList}
          show={TalentPoolShow}
          options={{ label: 'Talent pool' }}
        />
        <Resource
          name="submitted_questions"
          list={SubmittedQuestionList}
          show={SubmittedQuestionShow}
          edit={SubmittedQuestionEdit}
          options={{ label: 'Submitted questions' }}
          icon={QuestionAnswer}
        />
        <Resource name="job_opening_visitors" show={JobOpeningVisitorShow} />
        <Resource name="workspace_info" />
        <Resource
          name="test_gen_custom_requests"
          options={{ label: 'Requests' }}
          icon={LiveHelp}
          list={RequestList}
          show={RequestShow}
        />
        <Resource name="user_auth_providers" />
        <Resource
          name="experts"
          list={ExpertList}
          show={ExpertShow}
          icon={SupervisedUserCircleSharp}
        />
        <Resource
          name="expert_question_batches"
          list={ExpertQuestionBatchList}
          icon={List}
        />
        <Resource
          name="expert_questions"
          list={ExpertQuestionList}
          icon={ListAlt}
        />
        <Resource
          name="admin_users"
          list={AdminUserList}
          create={AdminUserCreate}
          icon={SupervisorAccountIcon}
        />
        <Resource name="expert_challenges" />
        <Resource
          name="system_email_templates"
          options={{ label: 'Email templates' }}
          list={EmailTemplatesList}
          show={EmailTemplatesShow}
          edit={EmailTemplatesEdit}
          create={EmailTemplatesCreate}
          icon={EmailIcon}
        />
      </Admin>
      <GoogleReCaptcha />
    </>
  ) : null;
};
