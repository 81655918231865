import {
  GET_LIST,
  GET_MANY,
  GET_ONE,
  DELETE,
  DELETE_MANY,
  UPDATE,
  CREATE,
} from 'react-admin';
import { cloneDeep } from 'lodash';
import api from './api';
import { uploadImage } from './images';

export default async (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { pagination, filter } = params;

      const { data } = await api.get('/support/test-generator/questions', {
        params: {
          offset: (pagination.page - 1) * pagination.perPage,
          limit: pagination.perPage,
          'is-reviewed': filter.is_reviewed,
          'test-gen-skill-id': filter.test_gen_skill_id,
          state: filter.state,
          'test-type': filter.test_type,
          'is-example': filter.is_example,
          'is-assessment': filter.is_assessment,
        },
      });

      if (data.total > 0) {
        return { data: data.questions, total: data.total };
      } else {
        return { data: [], total: 0 };
      }
    }

    case GET_MANY: {
      const { ids } = params;

      if (ids.length === 0) {
        return { data: [], total: 0 };
      }

      const { data } = await api.get('/support/test-generator/questions', {
        params: { id: ids.join(','), limit: 1000 },
      });

      if (data.total > 0) {
        return { data: data.questions, total: data.total };
      } else {
        return { data: [], total: 0 };
      }
    }

    case GET_ONE: {
      const { id } = params;
      const { data } = await api.get(`/support/test-generator/questions/${id}`);
      return { data };
    }

    case DELETE: {
      await api.delete(`/support/test-generator/questions/${params.id}`);
      return { data: params.previousData };
    }

    case DELETE_MANY: {
      await Promise.all(
        params.ids.map(id =>
          api.delete(`/support/test-generator/questions/${id}`)
        )
      );

      return { data: params.ids };
    }

    case UPDATE: {
      const { type, data } = params;

      if (type === 'UPDATE_QUESTION_STATE') {
        if (data.state === 'draft') {
          await api.post(
            `/support/test-generator/questions/${data.id}/unpublish`
          );
        } else {
          await api.post(
            `/support/test-generator/questions/${data.id}/publish`
          );
        }

        return { data };
      }

      if (type === 'UPDATE_QUESTION_ASSESSMENT') {
        await api.put(`/support/test-generator/questions/${data.id}`, {
          ...data,
          is_assessment: data.is_assessment,
        });

        return { data };
      } else {
        if (
          data.question_type === 'free-text' ||
          data.question_type === 'code-input' ||
          data.question_type === 'video-question'
        ) {
          data.options = null;
        }

        const response = await api.put(
          `/support/test-generator/questions/${data.id}`,
          await uploadPictureOptions(data)
        );

        return { data: response.data };
      }
    }

    case CREATE: {
      const { data } = params;

      if (
        data.question_type === 'free-text' ||
        data.question_type === 'code-input' ||
        data.question_type === 'video-question'
      ) {
        data.options = null;
      }

      const response = await api.post(
        '/support/test-generator/questions',
        await uploadPictureOptions(data)
      );

      return { data: response.data };
    }

    default:
      throw new Error(`Unsupported request: ${type}`);
  }
};

const uploadPictureOptions = async data => {
  if (data.options) {
    data = cloneDeep(data);

    for (const option of data.options) {
      if (option.content instanceof File) {
        const file = option.content;
        const url = await uploadImage(file, 'picture-question');
        option.content = url;
      }
    }
  }

  return data;
};
