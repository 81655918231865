import { GET_MANY_REFERENCE, CREATE } from 'react-admin';
import api from './api';
import defaultDataProvider from './defaults';
import { getCommonParams } from './utils';

export default async (type, params) => {
  switch (type) {
    case GET_MANY_REFERENCE: {
      const { target, id, pagination, sort } = params;

      if (target === 'workspace_id') {
        const {
          data: { data: workspace_user_jt },
        } = await api.get(`/support/db/workspace-user-jt`, {
          params: {
            workspace_id: id,
            ...getCommonParams(pagination, sort),
          },
        });

        const { data } = await api.get(`/support/db/notification-settings`, {
          params: {
            offset: 0,
            limit: pagination.perPage,
            'workspace_user_jt_id.in': workspace_user_jt
              .map(wuj => wuj.id)
              .join(),
          },
        });

        return data;
      } else {
        throw new Error(`Unsupported target attribute: ${target}`);
      }
    }

    case CREATE: {
      const {
        data: {
          workspace_id,
          user_id,
          job_opening_id,
          email_notifications_state,
        },
      } = params;

      const {
        data: { data: workspace_user_jt },
      } = await api.get(`/support/db/workspace-user-jt`, {
        params: {
          offset: 0,
          workspace_id,
          user_id,
        },
      });

      const workspace_user_jt_id = workspace_user_jt[0].id;

      try {
        const { data } = await api.post('/support/db/notification-settings', {
          job_opening_id,
          workspace_user_jt_id,
          email_notifications_state,
        });

        return { data };
      } catch (error) {
        if (error.response.status === 500) {
          throw new Error('Email notifications settings already exist');
        } else throw error;
      }
    }

    default:
      return defaultDataProvider(type, 'notification-settings', params);
  }
};
