import React from 'react';
import Chip from '@material-ui/core/Chip';
import { ChipList } from '../common';

export const ContactDataField = props => {
  const { contact_source_type, contact_source_data_1 } = props.record.stats;
  const values = [contact_source_type, contact_source_data_1];

  return (
    <ChipList>
      {values
        .filter(value => value != null)
        .map((value, index) => (
          <Chip key={index} label={value} size="small" />
        ))}
    </ChipList>
  );
};

ContactDataField.defaultProps = {
  addLabel: true,
};
