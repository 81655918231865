import { GET_LIST } from 'react-admin';
import defaultDataProvider from './defaults';

const RESOURCE = 'test-taker-submitted-questions';

export default async (type, params) => {
  switch (type) {
    case GET_LIST: {
      const filter = { ...params.filter };

      if (filter.is_archived != null) {
        if (filter.is_archived) {
          filter['archived_at.not.is'] = 'null';
        } else {
          filter['archived_at.is'] = 'null';
        }

        delete filter.is_archived;
      }

      return defaultDataProvider(type, RESOURCE, { ...params, filter });
    }

    default:
      return defaultDataProvider(type, RESOURCE, params);
  }
};
