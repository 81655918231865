import React from 'react';
import { Create } from 'react-admin';
import { AdminUserForm } from './AdminUserForm';

export const AdminUserCreate = props => {
  return (
    <Create title="Add admin user" {...props}>
      <AdminUserForm {...props} />
    </Create>
  );
};
