import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

export const ExpertQuestionBatchList = props => {
  return (
    <List
      title="Expert Question Batches"
      filters={<ExpertQuestionBatchFilters />}
      {...props}
    >
      <Datagrid>
        <TextField source="id" label="ID" />

        <ReferenceField
          source="expert_challenge_id"
          reference="expert_challenges"
          label="Name"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="expert_id" label="Expert ID" />
        <ReferenceField
          source="expert_id"
          reference="experts"
          label="Expert name"
          linkType="show"
        >
          <ReferenceField
            source="user_id"
            reference="users"
            link={false}
            allowEmpty
          >
            <TextField source="name" label="Name" />
          </ReferenceField>
        </ReferenceField>
        <TextField source="deadline" label="Deadline" />
        <TextField source="required_questions" label="Required Questions" />
        <TextField source="status" label="Status" />
        <LinkToQuestions />
      </Datagrid>
    </List>
  );
};

const ExpertQuestionBatchFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" />
    <TextInput label="Search by expert ID" source="expert_id" />
  </Filter>
);

const LinkToQuestions = ({ record }) =>
  record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/expert_questions',
        search: `filter=${JSON.stringify({
          expert_question_batch_id: record.id,
        })}`,
      }}
    >
      Show questions
    </Button>
  ) : null;
