import React from 'react';
import get from 'lodash/get';
import Chip from '@material-ui/core/Chip';
import { ChipList } from '../common';

export const ClearbitField = ({ source, record = {} }) => {
  const data = get(record, source);

  const chips = [
    get(data, 'company.category.industry'),
    get(data, 'company.geo.countryCode') === 'US'
      ? `${get(data, 'company.geo.city')} ${get(data, 'company.geo.stateCode')}`
      : get(data, 'company.geo.country'),
    get(data, 'company.metrics.employeesRange'),
    get(data, 'person.employment.seniority'),
  ].filter(label => label != null);

  return (
    <ChipList>
      {chips.map((label, index) => (
        <Chip key={index} label={label} size="small" />
      ))}
    </ChipList>
  );
};

ClearbitField.defaultProps = {
  addLabel: true,
};
