const GOOGLE_RECAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/api.js';

const GOOGLE_RECAPTCHA_SCRIPT_ID = 'google-recaptcha-v3';

export const cleanGoogleRecaptcha = () => {
  const script = document.querySelector(`#${GOOGLE_RECAPTCHA_SCRIPT_ID}`);
  if (script) {
    script.remove();
  }
};

const isScriptInjected = () =>
  !!document.querySelector(`#${GOOGLE_RECAPTCHA_SCRIPT_ID}`);

export const injectGoogleReCaptchaScript = ({ onLoad }) => {
  if (isScriptInjected()) {
    onLoad();
    return;
  }

  const js = document.createElement('script');
  js.id = GOOGLE_RECAPTCHA_SCRIPT_ID;
  js.src = `${GOOGLE_RECAPTCHA_SCRIPT_URL}?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
  js.onload = onLoad;

  const headElement = document.getElementsByTagName('head')[0];

  headElement.appendChild(js);
};
