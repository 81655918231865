import React from 'react';
import { connect } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { updateNotificationSettings } from '../../actions';

export const NotificationSelect = connect(
  null,
  { update: updateNotificationSettings }
)(({ record, update, ...props }) => (
  <Select
    size="small"
    color="primary"
    value={record.email_notifications_state}
    onChange={() => {
      update(
        record,
        record.email_notifications_state === 'all' ? 'possible-hires' : 'all'
      );
    }}
    {...props}
  >
    <MenuItem value={'all'}>All</MenuItem>
    <MenuItem value={'possible-hires'}>Possible hires</MenuItem>
  </Select>
));
