import React from 'react';
import {
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  ImageInput,
  ImageField,
} from 'react-admin';

export const JobOpeningEdit = props => (
  <Edit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Summary">
        <TextInput source="name" />
        <TextInput source="slug" />
      </FormTab>
      <FormTab label="Sharing">
        <TextInput source="social_meta_title" />
        <TextInput source="social_meta_description" />
        <ImageInput source="social_meta_image" label="Social meta image">
          <ImageField source="src" />
        </ImageInput>
      </FormTab>
      <FormTab label="Branding">
        <ImageInput source="background_picture" label="Background picture">
          <ImageField source="src" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Edit>
);
