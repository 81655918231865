import React from 'react';
import { SimpleForm, TextInput, SelectInput, required } from 'react-admin';

export const EmailTemplatesForm = props => (
  <SimpleForm {...props} redirect="show">
    <TextInput source="name" validate={required()} label="Name" />
    <SelectInput
      source="template_type"
      choices={templateTypes}
      validate={required()}
    />
    <SelectInput source="kind" choices={templateKinds} validate={required()} />
    <SelectInput
      source="status"
      choices={templateStatuses}
      validate={required()}
    />
    <TextInput
      source="subject"
      validate={required()}
      label="Subject"
      fullWidth
    />
    <TextInput
      multiline
      source="message"
      validate={required()}
      label="Message"
      fullWidth
    />
  </SimpleForm>
);

const templateTypes = [
  { id: 'invitation', name: 'Invitation' },
  { id: 'next-stage-invitation', name: 'Next stage invitation' },
  { id: 'video-invitation', name: 'Video invitation' },
  { id: 'resend-invitation', name: 'Resend invitation' },
  { id: 'resend-next-stage-invitation', name: 'Resend next stage invitation' },
  { id: 'video-feedback', name: 'Video feedback' },
  { id: 'rejection', name: 'Rejection' },
  { id: 'contact-candidate', name: 'Contact candidate' },
];

const templateKinds = [
  { id: 'manual', name: 'Manual' },
  { id: 'automatic', name: 'Automatic' },
];

const templateStatuses = [
  { id: 'active', name: 'Active' },
  { id: 'archived', name: 'Archived' },
];
