import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
} from 'react-admin';

export const SkillCategoryList = props => {
  return (
    <List title="Skill Categories" {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" label="Name" />
        <NumberField source="order_weight" label="Order weight" />
        <BooleanField source="is_hidden" label="Hidden" />
      </Datagrid>
    </List>
  );
};
