import React from 'react';
import { Button } from 'react-admin';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import configuration from '../../configuration';

const RecurlyButton = ({ className, record = {} }) => (
  <Button
    component="a"
    color="primary"
    label="Recurly"
    className={className}
    href={`${configuration.recurly.url}/accounts/${record.recurly_account_code}`}
  >
    <CreditCardIcon />
  </Button>
);

export default RecurlyButton;
