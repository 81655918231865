import React from 'react';
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  required,
} from 'react-admin';

export const AdminUserForm = props => {
  return (
    <SimpleForm {...props}>
      <ReferenceInput
        label="User"
        source="user_id"
        reference="users"
        validate={required()}
        filterToQuery={filter => ({ 'email.like': filter })}
      >
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
    </SimpleForm>
  );
};
