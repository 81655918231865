import { UPDATE, GET_LIST, GET_ONE } from 'react-admin';
import defaultDataProvider from './defaults';

export default async function(type, params) {
  switch (type) {
    case GET_LIST: {
      if (params.filter && params.filter.position_name_id) {
        const positionNameResponse = await defaultDataProvider(
          GET_ONE,
          'test_gen_position_names',
          { id: params.filter.position_name_id }
        );

        if (positionNameResponse && positionNameResponse.data) {
          delete params.filter.position_name_id;
          params.filter.id = positionNameResponse.data.test_gen_position_id;
        }
      }

      return defaultDataProvider(type, 'test_gen_positions', params);
    }
    case UPDATE: {
      return defaultDataProvider(type, 'test_gen_positions', params);
    }

    default:
      return defaultDataProvider(type, 'test_gen_positions', params);
  }
}
