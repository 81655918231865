import React from 'react';
import { get } from 'lodash';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

export const FavoriteField = ({ record = {}, source }) => {
  const favorite = get(record, source);

  if (favorite) {
    return <StarIcon />;
  } else {
    return <StarBorderIcon />;
  }
};

FavoriteField.defaultProps = {
  addLabel: true,
};
