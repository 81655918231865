import React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';

const PositionNameForm = props => {
  const redirect = (id, basePath, data) =>
    `/test_gen_positions/${data.test_gen_position_id}/show/1`;

  return (
    <SimpleForm {...props} redirect={redirect}>
      <TextInput source="name" validate={required()} />
    </SimpleForm>
  );
};

export default PositionNameForm;
