import React from 'react';
import { Edit } from 'react-admin';
import { PositionTypeForm } from './PositionTypeForm';

export const PositionTypeEdit = props => {
  return (
    <Edit title="Edit position type" {...props}>
      <PositionTypeForm {...props} />
    </Edit>
  );
};
