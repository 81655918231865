import { GET_LIST } from 'react-admin';
import { keyBy } from 'lodash';
import api from './api';
import defaultDataProvider from './defaults';

const parseUser = (data, workspaceUserJt) => ({
  ...data,
  role: workspaceUserJt.workspace_role,
});

export default async (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { filter } = params;

      if (filter && filter.workspace_id) {
        const {
          data: { data: workspace_user_jt, total },
        } = await api.get(`/support/db/workspace-user-jt`, {
          params: {
            offset: 0,
            workspace_id: filter.workspace_id,
          },
        });

        const {
          data: { data: users },
        } = await api.get(`/support/db/users`, {
          params: {
            offset: 0,
            'id.in': workspace_user_jt.map(wuj => wuj.user_id).join(),
          },
        });

        const workspaceUserJtByUserId = keyBy(workspace_user_jt, 'user_id');

        return {
          data: users.map(item =>
            parseUser(item, workspaceUserJtByUserId[item.id])
          ),
          total,
        };
      } else {
        return defaultDataProvider(type, 'users', params);
      }
    }

    default:
      return defaultDataProvider(type, 'users', params);
  }
};
