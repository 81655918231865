import React from 'react';
import get from 'lodash/get';
import {
  EditButton,
  Show,
  TopToolbar,
  FunctionField,
  TextField,
  ReferenceField,
  NumberField,
  SimpleShowLayout,
} from 'react-admin';
import ReactMarkdown from 'react-markdown';
import { Content, Question, QuestionTitle } from '@hundred5/design-system';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { QuestionAnswer } from './QuestionAnswer';
import { QuestionStateButton } from './QuestionStateButton';
import types from './types';

export const QuestionShow = props => {
  const classes = useStyles();

  return (
    <Show
      actions={<QuestionShowActions />}
      aside={<QuestionShowAside />}
      classes={classes}
      {...props}
    >
      <QuestionShowLayout />
    </Show>
  );
};

const useStyles = makeStyles(theme => ({
  card: {
    marginRight: theme.spacing(3),
  },
}));

const QuestionShowActions = ({ basePath, data, resource }) =>
  data ? (
    <TopToolbar>
      {data.state === 'draft' && (
        <EditButton basePath={basePath} record={data} resource={resource} />
      )}

      <QuestionStateButton
        basePath={basePath}
        record={data}
        resource={resource}
      />
    </TopToolbar>
  ) : null;

const QuestionShowLayout = ({ record }) => {
  const classes = useLayoutStyles();

  return (
    <div className={classes.container}>
      <Question
        header={<QuestionTitle>{get(record, 'title')}</QuestionTitle>}
        description={
          <Content>
            <ReactMarkdown source={get(record, 'description')} />
          </Content>
        }
        answer={<QuestionAnswer record={record} />}
      />

      {get(record, 'notes') ? (
        <div className={classes.notes}>
          <div>Notes:</div>
          <div>
            <Content>
              <ReactMarkdown source={get(record, 'notes')} />
            </Content>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const useLayoutStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(3),
    '& *': {
      boxSizing: 'border-box',
    },
  },
  notes: {
    marginTop: 20,
    '& > div:first-child': {
      color: 'rgba(0, 0, 0, 0.54)',
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      marginBottom: 5,
    },
    '& > div:last-child': {
      background: '#ffffff',
      boxShadow: '0px 1px 4px rgba(0,0,0,0.1)',
      padding: 20,
    },
  },
}));

const QuestionShowAside = props => {
  const classes = useAsideStyles();

  return (
    <Card className={classes.container}>
      <SimpleShowLayout {...props}>
        <NumberField source="impressions" />
        <ReferenceField
          label="Skill"
          reference="test_gen_skills"
          source="test_gen_skill_id"
          link="show"
        >
          <TextField source="skill" />
        </ReferenceField>
        <FunctionField
          label="Question type"
          render={({ question_type }) => types[question_type]}
        />
        <NumberField source="points" />
        <FunctionField
          label="Duration"
          render={record => `${record.duration_in_seconds} seconds`}
        />
        <TextField source="difficulty" />
        <TextField source="test_type" />
      </SimpleShowLayout>
    </Card>
  );
};

const useAsideStyles = makeStyles(theme => ({
  container: {
    width: 200,
  },
}));
