import React from 'react';
import { connect } from 'react-redux';
import PublishIcon from '@material-ui/icons/Publish';
import CheckBox from '@material-ui/icons/CheckBox';
import UndoIcon from '@material-ui/icons/Undo';
import { Button } from 'react-admin';
import { updateQuestionState, updateQuestionAssessment } from '../../actions';

export const QuestionStateButton = connect(
  null,
  { updateQuestionState, updateQuestionAssessment }
)(
  ({
    record,
    updateQuestionState,
    updateQuestionAssessment,
    basePath,
    ...props
  }) => (
    <>
      <Button
        size="small"
        color="primary"
        label={record.state === 'draft' ? 'Publish' : 'Unpublish'}
        onClick={e => {
          e.stopPropagation();
          updateQuestionState(
            record,
            record.state === 'draft' ? 'live' : 'draft'
          );
        }}
        {...props}
      >
        {record.state === 'draft' ? <PublishIcon /> : <UndoIcon />}
      </Button>
      {record.state === 'draft' && (
        <Button
          size="small"
          color="primary"
          label={
            record.is_assessment ? 'Unmark as assessment' : 'Mark as assessment'
          }
          onClick={e => {
            e.stopPropagation();
            updateQuestionAssessment(record, !record.is_assessment);
          }}
          {...props}
        >
          {record.is_assessment ? <UndoIcon /> : <CheckBox />}
        </Button>
      )}
    </>
  )
);
