import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  ReferenceField,
} from 'react-admin';

export const SkillTypeList = props => {
  return (
    <List title="Skill Types" {...props}>
      <Datagrid rowClick="show">
        <TextField source="name" label="Name" />
        <NumberField source="order_weight" label="Order weight" />
        <BooleanField source="is_hidden" label="Hidden" />
        <ReferenceField
          label="Skill Category"
          source="category_id"
          reference="test_gen_skill_categories"
        >
          <TextField source="name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
