import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  ReferenceField,
  DateField,
  ReferenceManyField,
  Datagrid,
  ShowButton,
} from 'react-admin';
import { PercentageField } from '../fields';

const TalentPoolShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Info">
        <TextField source="full_name" />
        <TextField source="authentication_email" />
        <TextField source="contact_email" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="country" />
        <BooleanField source="is_willing_to_work_remotely" />
        <TextField source="github_profile" />
        <TextField source="linkedin_profile" />
      </Tab>

      <Tab label="Job openings">
        <ReferenceManyField
          reference="candidates"
          source="authentication_email"
          target="email"
          addLabel={false}
        >
          <Datagrid>
            <DateField source="started_at" label="Took the test" />
            <ReferenceField
              source="job_opening_id"
              reference="job_openings"
              label="Job opening"
              link="show"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <PercentageField
              source="overall_score_as_percents"
              label="Score"
              sortable={false}
            />
            <ShowButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

export default TalentPoolShow;
