export const getJobOpeningStatus = data => {
  if (data.state) {
    return data.state;
  } else if (data.published_at == null && data.closed_at == null) {
    return 'draft';
  } else if (data.published_at != null && data.closed_at == null) {
    return 'published';
  } else {
    return 'closed';
  }
};
