import React from 'react';
import { Create } from 'react-admin';
import { SkillCategoryForm } from './SkillCategoryForm';

export const SkillCategoryCreate = props => {
  return (
    <Create title="Create skill category" {...props}>
      <SkillCategoryForm {...props} />
    </Create>
  );
};
