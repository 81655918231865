import React from 'react';
import { Create } from 'react-admin';
import { PositionTypeForm } from './PositionTypeForm';

export const PositionTypeCreate = props => {
  return (
    <Create title="Create position type" {...props}>
      <PositionTypeForm {...props} />
    </Create>
  );
};
