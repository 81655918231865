import React from 'react';
import { Edit, SimpleForm, TextInput } from 'react-admin';

export const SubmittedQuestionEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <TextInput source="question.topic" label="Topic" />
        <TextInput source="question.difficulty" label="Difficulty" />
        <TextInput source="question.text" label="Text" />
        <TextInput
          source="question.rightAnswers"
          label="Right answers"
          multiline
        />
        <TextInput
          source="question.wrongAnswers"
          label="Wrong answers"
          multiline
        />
      </SimpleForm>
    </Edit>
  );
};
