import React from 'react';
import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DeleteButton,
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
} from 'react-admin';
import { QuestionStateButton } from './QuestionStateButton';
import types from './types';

export const QuestionList = props => (
  <List title="Questions" filters={<QuestionFilter />} {...props}>
    <Datagrid rowClick="show">
      <FunctionField
        label="Type"
        render={({ question_type }) => `${types[question_type]}`}
      />
      <ReferenceField
        label="Skill"
        reference="test_gen_skills"
        source="test_gen_skill_id"
        link="show"
      >
        <TextField source="skill" />
      </ReferenceField>
      <BooleanField label="Is Assessment" source="is_assessment" />
      <TextField label="Title" source="title" />
      <NumberField source="impressions" />
      <QuestionStateButton />
      <DeleteButton undoable={false} />
    </Datagrid>
  </List>
);

const QuestionFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="test_gen_skill_id"
      reference="test_gen_skills"
      label="Skill"
      perPage={1000}
      sort={{ field: 'skill', order: 'ASC' }}
      filter={{ exclude_prefixes: ['HMWA', 'VQ'] }}
    >
      <SelectInput optionText="skill" optionValue="id" />
    </ReferenceInput>
    <SelectInput
      label="State"
      source="state"
      choices={[{ id: 'draft', name: 'Draft' }, { id: 'live', name: 'Live' }]}
    />
    <SelectInput
      label="Reviewed"
      source="is_reviewed"
      choices={[{ id: true, name: 'Yes' }, { id: false, name: 'No' }]}
    />
    <SelectInput
      label="Test type"
      source="test_type"
      choices={[
        { id: 'quiz', name: 'Quiz' },
        { id: 'video', name: 'Video' },
        { id: 'homework', name: 'Homework' },
        { id: 'interview', name: 'Interview' },
      ]}
    />
    <BooleanInput source="is_example" label="Example" />
    <BooleanInput source="is_assessment" label="Assessment" />
  </Filter>
);
