import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';

export const RequestList = props => {
  return (
    <List title="Requests" {...props}>
      <Datagrid rowClick="show">
        <TextField source="type" label="Type" />
        <TextField source="name" label="Name" />
        <ReferenceField
          source="user_id"
          label="User"
          reference="users"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
