import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';
import React from 'react';

export const AfterClosingFeedbackField = ({ source, record = {} }) => {
  const feedback = get(record, source);

  if (feedback.reason.type === 'hired_from_h5') {
    return (
      <Typography component="span" variant="body1">
        Hired someone from Toggl Hire
      </Typography>
    );
  } else if (feedback.reason.type === 'hired_elsewhere') {
    return (
      <Typography component="span" variant="body1">
        Hired someone elsewhere
      </Typography>
    );
  } else if (feedback.reason.type === 'other') {
    return (
      <Typography component="span" variant="body1">
        {feedback.reason.comment || 'Other'}
      </Typography>
    );
  } else {
    return null;
  }
};

AfterClosingFeedbackField.defaultProps = {
  addLabel: true,
};
