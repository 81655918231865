import React from 'react';
import { Edit } from 'react-admin';
import { PositionCategoryForm } from './PositionCategoryForm';

export const PositionCategoryEdit = props => {
  return (
    <Edit title="Edit position category" {...props}>
      <PositionCategoryForm {...props} />
    </Edit>
  );
};
