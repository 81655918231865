import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import WorkIcon from '@material-ui/icons/Work';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import VideoIcon from '@material-ui/icons/VideoLibrary';
import { ChipList } from '../common';

export const StatsField = props => {
  const classes = useChipStyles(props);

  const {
    total_published_job_openings,
    total_closed_job_openings,
    total_workspace_users,
    total_test_takers,
    total_visitors,
    total_video_intro_invites_sent,
    total_video_intros_finished,
  } = props.record.stats;

  const active = total_test_takers >= 5;

  return (
    <ChipList>
      <Chip
        className={classes.jobOpenings}
        label={[total_published_job_openings, total_closed_job_openings].join(
          ' / '
        )}
        icon={<WorkIcon />}
        title="Total published and closed job openings"
        size="small"
      />
      <Chip
        className={classes.users}
        icon={<BusinessIcon />}
        label={`${total_workspace_users}`}
        title="Total workspace members"
        size="small"
      />
      <CandidateNumbersChip
        record={props.record}
        className={classes.candidates}
      />
      {active ? (
        <>
          <CandidateFeaturesChip
            record={props.record}
            className={classes.features}
          />
        </>
      ) : (
        <>
          <Chip
            className={classes.visitors}
            label={`${total_visitors}`}
            icon={<MeetingRoomIcon />}
            title="Total landing page visitors"
            size="small"
          />
        </>
      )}
      <Chip
        className={classes.extension}
        label="EXT"
        size="small"
        title="Used browser extension?"
      />
      <Chip
        className={classes.linkedin}
        label="LI"
        size="small"
        title="Signed up with LinkedIn?"
      />
      <Chip
        className={classes.videoIntros}
        label={[
          total_video_intro_invites_sent,
          total_video_intros_finished,
        ].join(' / ')}
        icon={<VideoIcon />}
        title="Total video intros sent and finished"
        size="small"
      />
    </ChipList>
  );
};

const useChipStyles = makeStyles(theme => ({
  jobOpenings: props =>
    getRangeStyle(props.record.stats.total_published_job_openings, [1, 3]),
  users: props =>
    getRangeStyle(props.record.stats.total_workspace_users, [2, 3]),
  candidates: props =>
    getRangeStyle(props.record.stats.total_test_takers, [1, 11]),
  features: props =>
    getBooleanStyle(
      props.record.stats.total_test_takers_with_ratings +
        props.record.stats.total_test_takers_with_notes +
        props.record.stats.total_test_takers_with_tags >
        0
    ),
  visitors: props => getRangeStyle(props.record.stats.total_visitors, [2, 51]),
  extension: props =>
    getBooleanStyle(props.record.stats.used_browser_extension),
  linkedin: props =>
    getBooleanStyle(props.record.stats.signed_up_with_linkedin),
  videoIntros: props =>
    getBooleanStyle(
      props.record.stats.total_video_intro_invites_sent > 0 &&
        props.record.stats.total_video_intros_finished ===
          props.record.stats.total_video_intro_invites_sent
    ),
}));

const getBooleanStyle = value =>
  value ? { backgroundColor: green[300] } : { backgroundColor: grey[300] };

const getRangeStyle = (value, thresholds) => {
  if (value == null) return grey[300];

  if (value < thresholds[0]) {
    return { backgroundColor: grey[300] };
  } else if (value < thresholds[1]) {
    return { backgroundColor: yellow[300] };
  } else {
    return { backgroundColor: green[300] };
  }
};

StatsField.defaultProps = {
  addLabel: true,
};

const CandidateNumbersChip = props => {
  const { record, ...rest } = props;

  const label = [
    record.stats.total_test_takers,
    record.stats.total_test_takers_last_week,
    record.stats.total_test_takers_unlocked,
    (
      Math.round((record.stats.total_test_takers_last_week / 7) * 100) / 100
    ).toFixed(2),
  ].join(' / ');

  return (
    <Chip
      {...rest}
      icon={<PersonIcon />}
      size="small"
      title="Total candidates, candidates finished in last 7 days, unlocked candidates, average candidates per day in the last 7 days"
      label={label}
    />
  );
};

const CandidateFeaturesChip = props => {
  const { record, ...rest } = props;

  const label = [
    ['R', record.stats.total_test_takers_with_ratings],
    ['N', record.stats.total_test_takers_with_notes],
    ['T', record.stats.total_test_takers_with_tags],
  ]
    .filter(([, value]) => value > 0)
    .map(([char]) => char)
    .join('');

  return (
    <Chip
      {...rest}
      title="Are there candidates with [R]ating, [N]otes or [T]ags?"
      icon={<StarBorderIcon />}
      label={label || '-'}
      size="small"
    />
  );
};
