import React from 'react';
import get from 'lodash/get';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';

const MarkdownField = ({ record = {}, source }) => {
  const markdown = get(record, source);

  return (
    <Typography variant="body2">
      <ReactMarkdown source={markdown} />
    </Typography>
  );
};

MarkdownField.defaultProps = {
  addLabel: true,
};

export default MarkdownField;
