import { GET_MANY } from 'react-admin';
import api from './api';

export default async (type, params) => {
  if (type === GET_MANY) {
    const { ids } = params;

    const response = await api.get('/support/workspace-info', {
      params: { id: ids.join(',') },
    });

    const { workspaces } = response.data;
    return { data: workspaces.map(parse) };
  }

  throw new Error('Method not supported');
};

const parse = data => {
  return {
    ...data,
    id: data.workspace_id,
    user_data_last_used: data.user_data_last_used
      ? new Date(data.user_data_last_used)
      : null,
  };
};
