import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  required,
  minValue,
} from 'react-admin';
import { ExperienceLevelInput } from './ExperienceLevelInput';
import { MarkdownInput } from '../inputs/markdown_input';

export const PositionEdit = props => (
  <Edit {...props}>
    <SimpleForm {...props}>
      <ReferenceInput
        label="Primary Name"
        source="primary_test_gen_position_name_id"
        reference="test_gen_position_names"
        filter={{ test_gen_position_id: props.id }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        label="Position Type"
        source="test_gen_position_type_id"
        reference="test_gen_position_types"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ExperienceLevelInput
        source="experience_level"
        label="Experience level"
      />
      <BooleanInput source="is_premium" label="Premium" />
      <BooleanInput source="is_hidden" label="Hidden" />
      <NumberInput
        source="order_weight"
        label="Order weight"
        defaultValue={0}
        validate={[required(), minValue(0)]}
      />
      <MarkdownInput label="Description" source="test_template_description" />
    </SimpleForm>
  </Edit>
);
