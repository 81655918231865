import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin';

export const JobOpeningVisitorShow = props => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <DateField source="created_at" label="Visited" />
        <ReferenceField
          source="test_taker_id"
          reference="candidates"
          label="Candidate"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="job_opening_id"
          reference="job_openings"
          label="Job opening"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="source_url" label="Source URL" />
        <TextField source="source_site" label="Source site" />
        <TextField source="utm_source" label="UTM source" />
        <TextField source="utm_medium" label="UTM medium" />
        <TextField source="utm_campaign" label="UTM campaign" />
      </SimpleShowLayout>
    </Show>
  );
};
