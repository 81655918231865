import api from './api';

export async function authenticate(email, password) {
  const response = await api.post(
    '/auth/authenticate',
    {
      email,
      password,
    },
    { captchaAction: 'login' }
  );

  return response.data.access_token;
}

export async function fetchMe() {
  const response = await api.get('/me');
  const { data } = response;

  return {
    id: data.id,
    email: data.email,
    name: data.name,
    profilePictureUrl: data.profile_picture_url,
  };
}
