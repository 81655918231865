import React, { useState } from 'react';
import { FunctionField, Labeled } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { StringField, BoolField } from './CommonFields';

const TestTakerTestsField = props => {
  const tests = props.tests;

  return (
    <TestTakerTests>
      {tests.map(test => (
        <TestTakerTest
          key={test.test_id}
          test={test}
          {...props}
        ></TestTakerTest>
      ))}
    </TestTakerTests>
  );
};

export default TestTakerTestsField;

const TestTakerTest = props => {
  const [open, setOpen] = useState(false);

  const test = props.test;
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <div className={classes.rowHeader}>
        <div>
          <Labeled label="Started At" className={classes.createdAt}>
            <FunctionField
              render={() => {
                return new Date(test.start_date).toLocaleDateString();
              }}
            />
          </Labeled>
          <StringField label="Test ID" value={test.test_id} />
          <StringField label="Test type" value={test.test_type} />
        </div>
        <MuiButton color="primary" onClick={() => setOpen(!open)}>
          {open ? 'Close' : 'Open'}
        </MuiButton>
      </div>
      {open && (
        <>
          <StringField label="Candidate state" value={test.candidate_state} />
          <StringField label="Status" value={test.status} />
          <StringField label="Start date" value={test.start_date} />
          <StringField label="Finish date" value={test.finish_date} />
          <BoolField label="Submitted in time" value={test.submitted_in_time} />
          <StringField label="Percent" value={`${test.percent} %`} />
          <StringField label="Points" value={test.points} />
          <StringField
            label="Original percent to pass"
            value={test.original_percent_to_pass}
          />
          <BoolField label="Evaluated" value={test.is_evaluated} />
        </>
      )}
    </Card>
  );
};

const TestTakerTests = props => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(-0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
    padding: '12px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  rowHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  createdAt: {
    marginRight: '20px',
  },
}));
