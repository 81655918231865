import React from 'react';

const SkillTitle = ({ record }) => {
  return (
    <span>
      Skill #{record.id} - {record.skill}
    </span>
  );
};

export default SkillTitle;
