import { GET_LIST } from 'react-admin';
import api from './api';
import { getCommonParams } from './utils';
import defaultDataProvider from './defaults';
import { getRegionCountries } from '../utils/regions';

export default async (type, params) => {
  if (type === GET_LIST) {
    const { pagination, filter } = params;

    const response = await api.get(`/support/talent-pool/test-takers`, {
      params: {
        ...getCommonParams(pagination, null),
        'job-opening-name': filter.job_opening_name,
        countries: getCountries(filter),
        remote: filter.remote != null ? Number(filter.remote) : undefined,
        'contact-email': filter.email,
        'skills.eq': filter.skills_all ? filter.skills_all.join() : undefined,
        'skills.in': filter.skills_some ? filter.skills_some.join() : undefined,
      },
    });

    const { total, data } = response.data;
    return { data: data || [], total: total };
  }

  return defaultDataProvider(type, 'talent-pool-test-takers', params);
};

const getCountries = filter => {
  if (filter.countries) {
    return filter.countries;
  } else if (filter.region) {
    return getRegionCountries(filter.region);
  }
};
