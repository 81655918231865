import { CREATE, UPDATE, DELETE } from 'react-admin';
import api from './api';
import defaultDataProvider from './defaults';

export default async function(type, params) {
  switch (type) {
    case CREATE:
    case UPDATE: {
      const data = await defaultDataProvider(
        type,
        'test_gen_position_test_gen_skill_jt',
        params
      );

      if (params.data && params.data.test_gen_position_id) {
        await api.post(
          `/tests/generator/positions/${params.data.test_gen_position_id}/recalculate`
        );
      }

      return data;
    }

    case DELETE: {
      const data = await defaultDataProvider(
        type,
        'test_gen_position_test_gen_skill_jt',
        params
      );

      if (params.previousData && params.previousData.test_gen_position_id) {
        await api.post(
          `/tests/generator/positions/${params.previousData.test_gen_position_id}/recalculate`
        );
      }

      return data;
    }

    default:
      return defaultDataProvider(
        type,
        'test_gen_position_test_gen_skill_jt',
        params
      );
  }
}
