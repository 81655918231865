import React from 'react';
import { connect } from 'react-redux';
import MuiButton from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PublishIcon from '@material-ui/icons/Publish';
import { Button } from 'react-admin';
import { publishJobOpening } from '../../actions';

class InnerPublishButton extends React.Component {
  state = {
    open: false,
    coupon: '',
  };

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  updateCoupon(event) {
    this.setState({ coupon: event.target.value });
  }

  submit() {
    this.props.publishJobOpening(this.props.record, this.state.coupon);
  }

  render() {
    const { record } = this.props;
    const { open, coupon } = this.state;

    if (record == null || record.state !== 'closed') return null;

    return (
      <React.Fragment>
        <Button
          label="Re-open"
          onClick={() => {
            this.open();
          }}
        >
          <PublishIcon />
        </Button>

        <Dialog
          open={open}
          onClose={() => {
            this.close();
          }}
        >
          <DialogTitle>Re-open job opening</DialogTitle>

          {record.subscribed_at != null ? (
            <DialogContent>
              <DialogContentText>
                A valid coupon code is required to re-open this job opening.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Coupon code"
                type="text"
                fullWidth
                value={coupon}
                onChange={event => {
                  this.updateCoupon(event);
                }}
              />
            </DialogContent>
          ) : (
            <DialogContent>
              <DialogContentText>
                Are you sure you want to re-open this job opening?
              </DialogContentText>
            </DialogContent>
          )}

          <DialogActions>
            <MuiButton
              color="default"
              onClick={() => {
                this.close();
              }}
            >
              Cancel
            </MuiButton>
            <MuiButton
              color="primary"
              onClick={() => {
                this.close();
                this.submit();
              }}
            >
              Re-open
            </MuiButton>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

export const PublishButton = connect(
  null,
  { publishJobOpening }
)(InnerPublishButton);
