import React from 'react';
import {
  ShowView,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  TopToolbar,
  EditButton,
  DeleteButton,
  Button,
  Toolbar,
  SaveButton,
  useShowController,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import trim from 'lodash/trim';
import { ArchiveButton } from './ArchiveButton';
import { AddToLibraryButton } from './AddToLibraryButton';
import { FavoriteButton } from './FavoriteButton';
import { QuestionForm } from '../questions/QuestionForm';
import { useAddToLibrary } from './addToLibrary';

const useStyles = makeStyles({
  multiline: { whiteSpace: 'pre-wrap' },
  drawer: {
    width: 550,
  },
});

export const SubmittedQuestionShow = props => {
  const controller = useShowController(props);
  const classes = useStyles();

  const [drawerQuestion, setDrawerQuestion] = React.useState(null);
  const [showDrawer, setShowDrawer] = React.useState(false);

  const questionFormProps = useAddToLibrary(props.id);

  return (
    <>
      <ShowView
        actions={
          <SubmittedQuestionActions
            onAddToLibrary={question => {
              setDrawerQuestion(question);
              setShowDrawer(true);
            }}
          />
        }
        {...props}
        {...controller}
      >
        <SimpleShowLayout>
          <DateField source="created_at" label="Submitted" />
          <ReferenceField
            source="test_taker_id"
            reference="candidates"
            label="Candidate"
            link="show"
          >
            <TextField source="email" />
          </ReferenceField>
          <ReferenceField
            source="test_gen_question_id"
            reference="questions"
            label="Library question"
            link="show"
          >
            <TextField source="title" />
          </ReferenceField>
          <TextField source="question.topic" label="Topic" />
          <TextField source="question.difficulty" label="Difficulty" />
          <TextField
            source="question.text"
            label="Text"
            className={classes.multiline}
          />
          <TextField
            source="question.rightAnswers"
            label="Right answers"
            className={classes.multiline}
          />
          <TextField
            source="question.wrongAnswers"
            label="Wrong answers"
            className={classes.multiline}
          />
          {controller.record && controller.record.question.importEmail ? (
            <TextField source="question.importEmail" label="Import email" />
          ) : null}
        </SimpleShowLayout>
      </ShowView>
      <Drawer open={showDrawer} variant="persistent" anchor="right">
        <div className={classes.drawer}>
          {drawerQuestion != null ? (
            <QuestionForm
              key={drawerQuestion.id}
              initialValues={getInitialQuestionFormValues(drawerQuestion)}
              toolbar={
                <QuestionFormToolbar onCancel={() => setShowDrawer(false)} />
              }
              {...questionFormProps}
            />
          ) : null}
        </div>
      </Drawer>
    </>
  );
};

const SubmittedQuestionActions = ({
  basePath,
  data,
  resource,
  onAddToLibrary,
}) => {
  return (
    <TopToolbar>
      <FavoriteButton record={data} resource={resource} />
      <AddToLibraryButton onClick={() => onAddToLibrary(data)} />
      <ArchiveButton basePath={basePath} record={data} resource={resource} />
      <EditButton basePath={basePath} record={data} resource={resource} />
      <DeleteButton basePath={basePath} record={data} resource={resource} />
    </TopToolbar>
  );
};

const getInitialQuestionFormValues = record => ({
  question_type: 'multiple-choice',
  title: record.question.topic,
  description: record.question.text,
  notes: record.question.notes,
  difficulty: record.question.difficulty,
  options: [
    ...parseQuestionOptions(record.question.rightAnswers, 1),
    ...parseQuestionOptions(record.question.wrongAnswers, 0),
  ],
});

const parseQuestionOptions = (source, points) => {
  const lines = trim(source)
    .split(/[\n\r]+/)
    .map(line => trim(line, ' -*'));

  return lines.map(line => ({
    content: line,
    order_weight: 0,
    points: points,
  }));
};

const QuestionFormToolbar = ({ onCancel, ...props }) => (
  <Toolbar {...props}>
    <SaveButton label="Save and archive" redirect="show" submitOnEnter={true} />
    <Button label="Cancel" variant="flat" onClick={onCancel} />
  </Toolbar>
);
