import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  DateField,
  ReferenceField,
  TopToolbar,
} from 'react-admin';
import { JSONField } from '../fields';
import UserStatusButton from './UserStatusButton';

export const UserShow = props => (
  <Show title={<UserTitle />} actions={<UserActions />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email" />
        <BooleanField source="is_email_verified" label="Verified" />
        <BooleanField source="is_suspended" label="Suspended" />
      </Tab>

      <Tab label="info">
        <JSONField source="clearbit.person" label="Clearbit" />
      </Tab>

      <Tab label="Workspaces">
        <ReferenceManyField
          reference="workspace_user_jt"
          target="user_id"
          addLabel={false}
        >
          <Datagrid rowClick="show">
            <TextField label="ID" source="workspace_id" />
            <DateField source="created_at" label="Added" />
            <ReferenceField source="workspace_id" reference="workspaces">
              <TextField source="name" />
            </ReferenceField>
            <TextField source="workspace_role" label="Role" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Third party auth">
        <ReferenceManyField
          reference="user_auth_providers"
          target="user_id"
          addLabel={false}
        >
          <Datagrid>
            <TextField label="Auth provider" source="auth_provider" />
            <TextField label="Auth provider ID" source="auth_provider_id" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const UserTitle = ({ record }) => {
  return (
    <span>
      User #{record.id} - {record.name || record.email}
    </span>
  );
};

const UserActions = ({ basePath, data }) => (
  <TopToolbar>
    <UserStatusButton basePath={basePath} record={data} />
  </TopToolbar>
);
