import { UPDATE } from 'react-admin';

export const publishJobOpening = (jobOpening, coupon) => ({
  type: 'PUBLISH_JOB_OPENING',
  payload: {
    id: jobOpening.id,
    previousData: jobOpening,
    data: { state: 'published', coupon },
  },
  meta: {
    fetch: UPDATE,
    resource: 'job_openings',
    onSuccess: {
      notification: {
        body: 'Job opening re-opened',
        level: 'info',
      },
    },
  },
});

export const supportLogIn = workspaceId => ({
  type: 'SUPPORT_LOG_IN',
  payload: { workspaceId },
});

export const updateNotificationSettings = (
  notificationSettings,
  email_notifications_state
) => ({
  type: 'UPDATE_NOTIFICATION_SETTINGS',
  payload: {
    id: notificationSettings.id,
    previousData: notificationSettings,
    data: { ...notificationSettings, email_notifications_state },
  },
  meta: {
    fetch: UPDATE,
    resource: 'notification_settings',
    onSuccess: {
      notification: {
        body:
          email_notifications_state === 'all'
            ? 'Email notifications set to all'
            : 'Email notifications set to possible hires',
        level: 'info',
      },
    },
  },
});

export const exportCandidate = (candidateId, shadowContactInfo) => ({
  type: 'EXPORT_CANDIDATE',
  payload: { candidateId, shadowContactInfo },
});

export const updateQuestionState = (question, state) => ({
  type: 'UPDATE_QUESTION_STATE',
  payload: {
    type: 'UPDATE_QUESTION_STATE',
    previousData: question,
    data: { ...question, state },
  },
  meta: {
    fetch: UPDATE,
    resource: 'questions',
    onSuccess: {
      notification: {
        body: state === 'live' ? 'Question published' : 'Question unpublished',
        level: 'info',
      },
    },
  },
});

export const updateQuestionAssessment = (question, is_assessment) => ({
  type: 'UPDATE_QUESTION_ASSESSMENT',
  payload: {
    type: 'UPDATE_QUESTION_ASSESSMENT',
    previousData: question,
    data: { ...question, is_assessment },
  },
  meta: {
    fetch: UPDATE,
    resource: 'questions',
    onSuccess: {
      notification: {
        body: is_assessment ? 'Marked as assessment' : 'Unmarked as assessment',
        level: 'info',
      },
    },
  },
});

export const updateUserStatus = (user, is_suspended) => ({
  type: 'UPDATE_USER_STATE',
  payload: {
    type: 'UPDATE_USER_STATE',
    id: user.id,
    previousData: user,
    data: { ...user, is_suspended },
  },
  meta: {
    fetch: UPDATE,
    resource: 'users',
    onSuccess: {
      notification: {
        body: is_suspended ? 'User suspended' : 'User activated',
        level: 'info',
      },
    },
  },
});
