import React from 'react';
import { Edit } from 'react-admin';
import { SkillCategoryForm } from './SkillCategoryForm';

export const SkillCategoryEdit = props => {
  return (
    <Edit title="Edit skill category" {...props}>
      <SkillCategoryForm {...props} />
    </Edit>
  );
};
