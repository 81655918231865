import { useState } from 'react';
import {
  useRedirect,
  useVersion,
  useDataProvider,
  useNotify,
} from 'react-admin';

export const useAddToLibrary = id => {
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const version = useVersion();

  const [saving, setSaving] = useState(false);

  const save = async (data, redirectTo) => {
    setSaving(true);

    let question;

    try {
      ({ data: question } = await dataProvider.create('questions', { data }));

      await dataProvider.update('submitted_questions', {
        id,
        data: {
          archived_at: new Date().toISOString(),
          test_gen_question_id: question.id,
        },
      });
    } catch (error) {
      setSaving(false);

      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning'
      );

      return;
    }

    notify('ra.notification.created', 'info');
    redirect(redirectTo, '/questions', question.id, question);
  };

  return {
    loading: false,
    loaded: true,
    saving,
    save,
    resource: 'questions',
    basePath: '/questions',
    record: {},
    redirect: 'show',
    version,
  };
};
