export const getPriority = record => {
  const { total_test_takers, usage_time, num_sessions } = record.stats;

  //  WS's w/ at least 2 non-demo TT w/ any score in any non-demo JO and at least 10min in the app and 2 sessions
  if (
    (total_test_takers >= 2 && usage_time >= 600 && num_sessions >= 2) ||
    total_test_takers >= 5
  ) {
    return 1;
  }

  //  WS's w/ at least 1 non-demo TT w/ any score in any non-demo JO and at least 10min in the app and 2 sessions
  if (total_test_takers >= 1 && usage_time >= 600 && num_sessions >= 2) {
    return 2;
  }

  //  WS's w/ at least 1 non-demo TT w/ any score in any non-demo JO and at least 10min in the app
  if (total_test_takers >= 1 && usage_time >= 600) {
    return 3;
  }

  // WS's w/ at least 1 non-demo TT w/ any score in any non-demo JO
  if (total_test_takers >= 1) {
    return 4;
  }

  return '';
};
