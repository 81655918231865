import React from 'react';
import {
  BooleanField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ImageField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  Show,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  ShowController,
  TopToolbar,
} from 'react-admin';
import { PainPointField } from '../workspaces/DataFields';
import { AfterClosingFeedbackField } from './AfterClosingFeedbackField';
import { CandidatesButton } from './CandidatesButton';
import { JobLocationField } from './JobLocationField';
import { PublishButton } from './PublishButton';
import { PreviewButton } from './PreviewButton';
import { ApplyButton } from './ApplyButton';

export const JobOpeningShow = props => (
  <ShowController {...props}>
    {controllerProps => {
      const after_closing_feedback =
        (controllerProps.record &&
          controllerProps.record.data &&
          controllerProps.record.data.after_closing_feedback) ||
        null;

      return (
        <Show actions={<Actions />} {...props} {...controllerProps}>
          <TabbedShowLayout>
            <Tab label="Summary">
              <TextField source="id" label="ID" />
              <ReferenceField
                source="workspace_id"
                reference="workspaces"
                link="show"
              >
                <WorkspaceField />
              </ReferenceField>
              <TextField source="name" />
              <TextField source="slug" />
              <TextField source="state" label="Status" />
              <DateField source="created_at" label="Created" showTime />
              <DateField source="updated_at" label="Updated" showTime />
              <DateField source="subscribed_at" label="Subscribed" showTime />
              <DateField source="terminated_at" label="Terminated" showTime />
              <ReferenceField
                source="id"
                reference="job_opening_stats"
                label="Candidates"
                link={false}
              >
                <NumberField source="test_takers" />
              </ReferenceField>
              <NumberField
                source="unlocked_test_takers"
                label="Unlocked candidates"
              />
            </Tab>

            <Tab label="Info">
              <JobLocationField
                source="data.job_location"
                label="Job location"
              />

              <ReferenceField
                source="workspace_id"
                reference="workspaces"
                label="Pain point"
                link={false}
              >
                <PainPointField
                  source="data.type_of_candidates"
                  label="Pain point"
                />
              </ReferenceField>

              <ReferenceField
                source="test_id"
                reference="tests"
                label="Generated test"
                link={false}
              >
                <BooleanField source="is_generated" />
              </ReferenceField>

              <ReferenceField
                source="test_id"
                reference="tests"
                label="Test time limit"
                link={false}
              >
                <ReferenceField
                  source="active_test_version_id"
                  reference="test_versions"
                  link={false}
                >
                  <FunctionField
                    render={record =>
                      `${(record.duration_in_seconds / 60).toFixed(0)} minutes`
                    }
                  />
                </ReferenceField>
              </ReferenceField>

              <ReferenceField
                source="test_id"
                reference="tests"
                label="Test threshold"
                link={false}
              >
                <ReferenceField
                  source="active_test_version_id"
                  reference="test_versions"
                  link={false}
                >
                  <FunctionField
                    render={record => `${record.percent_to_pass}%`}
                  />
                </ReferenceField>
              </ReferenceField>

              <ReferenceField
                source="test_id"
                reference="tests"
                label="Test skills"
                link={false}
              >
                <ReferenceManyField
                  target="test_id"
                  reference="test_gen_skill_test_jt"
                  link={false}
                >
                  <SingleFieldList link={false}>
                    <ReferenceField
                      source="test_gen_skill_id"
                      reference="test_gen_skills"
                      link={false}
                    >
                      <ChipField source="skill" />
                    </ReferenceField>
                  </SingleFieldList>
                </ReferenceManyField>
              </ReferenceField>
            </Tab>

            <Tab label="Sharing">
              <TextField source="social_meta_title" label="Preview title" />
              <TextField
                source="social_meta_description"
                label="Preview description"
              />
              <ImageField
                source="social_meta_image.src"
                label="Preview image"
                addLabel
              />
            </Tab>

            <Tab label="Branding">
              <ImageField
                source="background_picture.src"
                label="Background picture"
                addLabel
              />
            </Tab>

            <Tab label="Boosts">
              <ReferenceManyField
                reference="facebook_campaigns"
                target="job_opening_id"
                addLabel={false}
              >
                <Datagrid>
                  <DateField source="created_at" label="Requested at" />
                  <TextField source="location" label="Job location" />
                  <TextField
                    source="countries"
                    label="Countries to hire from"
                  />
                  <SelectField
                    source="boost_type"
                    choices={[
                      { id: 'free', name: 'Free' },
                      { id: 'level-1', name: '$50' },
                      { id: 'level-2', name: '$100' },
                      { id: 'level-3', name: '$150' },
                    ]}
                  />
                </Datagrid>
              </ReferenceManyField>
            </Tab>

            {after_closing_feedback ? (
              <Tab label="Feedback">
                <AfterClosingFeedbackField
                  source="data.after_closing_feedback"
                  label="Closed because"
                />
                <BooleanField
                  label="Positive experience"
                  source="data.after_closing_feedback.experience.is_positive"
                />
                <TextField
                  label="Experience feedback"
                  source="data.after_closing_feedback.experience.comment"
                />
                <BooleanField
                  label="Would use again"
                  source="data.after_closing_feedback.use_again.is_positive"
                />

                {after_closing_feedback.use_again &&
                after_closing_feedback.use_again.is_positive === false &&
                after_closing_feedback.use_again.comment !== null ? (
                  <TextField
                    label="Wouldn't use again because"
                    source="data.after_closing_feedback.use_again.comment"
                  />
                ) : null}
              </Tab>
            ) : null}
          </TabbedShowLayout>
        </Show>
      );
    }}
  </ShowController>
);

const Actions = ({ basePath, data }) => (
  <TopToolbar>
    <PreviewButton record={data} />
    <ApplyButton record={data} />
    <CandidatesButton record={data} />
    <PublishButton record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const WorkspaceField = props => (
  <FunctionField
    {...props}
    render={record => {
      const date = new Date(record.created_at).toLocaleDateString();
      return `${record.name} (signed up ${date})`;
    }}
  />
);
