import React from 'react';
import { connect } from 'react-redux';
import {
  Show,
  TextField,
  DeleteButton,
  SimpleShowLayout,
  TopToolbar,
  Labeled,
  Button,
  BooleanField,
} from 'react-admin';
import GetApp from '@material-ui/icons/GetApp';
import { exportCandidate } from '../../actions';
import TestTakerField from './TestTakers';

const CandidateShow = props => (
  <Show actions={<Actions />} title={<CandidateTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Candidate ID" />
      <TextField source="email" label="Email" />
      <TextField source="name" label="Name" />
      <BooleanField source="expert_consent" label="Expert consent" />
      <Labeled label="Test takers" />
      <TestTakerField source="test_takers" />
    </SimpleShowLayout>
  </Show>
);

const CandidateTitle = ({ record }) => {
  return (
    <span>
      Candidate #{record.id} - {record.name || record.email}
    </span>
  );
};

const CandidateExportButton = connect(
  null,
  { exportCandidate }
)(({ shadow = false, record, exportCandidate }) => (
  <Button
    size="small"
    color="primary"
    label={shadow ? 'Export without info' : 'Export with info'}
    onClick={e => {
      e.stopPropagation();
      exportCandidate(record.id, shadow);
    }}
  >
    <GetApp />
  </Button>
));

const Actions = ({ basePath, resource, data }) =>
  data != null ? (
    <TopToolbar>
      <CandidateExportButton shadow={true} record={data} />
      <CandidateExportButton shadow={false} record={data} />
      <DeleteButton
        basePath={basePath}
        resource={resource}
        record={data}
        undoable={false}
      />
    </TopToolbar>
  ) : null;

export default CandidateShow;
