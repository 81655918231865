import { find } from 'lodash';

export const getRegionChoices = () =>
  regions.map(region => ({ id: region.id, name: region.name }));

export const getRegionCountries = id => find(regions, { id }).countries;

const regions = [
  {
    id: 'eu',
    name: 'EU',
    countries: [
      'Austria',
      'Belgium',
      'Bulgaria',
      'Croatia',
      'Cyprus',
      'Czechia',
      'Denmark',
      'Estonia',
      'Finland',
      'France',
      'Germany',
      'Greece',
      'Hungary',
      'Ireland',
      'Italy',
      'Latvia',
      'Lithuania',
      'Luxembourg',
      'Malta',
      'Netherlands',
      'Poland',
      'Portugal',
      'Romania',
      'Slovakia',
      'Slovenia',
      'Spain',
      'Sweden',
      'United Kingdom',
    ],
  },
];
