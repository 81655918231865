import React from 'react';
import { Show, SimpleShowLayout, TextField, ReferenceField } from 'react-admin';

export const RequestShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <TextField source="type" label="Type" />
      <ReferenceField
        source="user_id"
        label="User"
        reference="users"
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="notes" label="Notes" />
    </SimpleShowLayout>
  </Show>
);
