import flatten from 'flat';
import { isEmpty, isPlainObject, omitBy } from 'lodash';
import { CREATE, DELETE, DELETE_MANY, GET_LIST, UPDATE } from 'react-admin';
import api from './api';
import defaultDataProvider from './defaults';
import { getCommonParams } from './utils';

export default async function(type, params) {
  switch (type) {
    case GET_LIST: {
      const {
        pagination,
        filter: { exclude_prefixes, ...filter },
        sort,
      } = params;

      const query = {
        ...getCommonParams(pagination, sort),
        ...omitBy(flatten(filter), isEmptyObject),
      };

      const response = await api.get('/support/db/test_gen_skills', {
        params: query,
      });

      const { total, data } = response.data;

      const filteredData =
        exclude_prefixes && exclude_prefixes.length > 0
          ? data.filter(
              ({ skill }) =>
                !exclude_prefixes.some(prefix => skill.startsWith(prefix))
            )
          : data;

      return {
        data: filteredData,
        total,
      };
    }

    case CREATE: {
      const { data } = await api.post(`/support/db/test_gen_skills`, {
        ...params.data,
        order_weight: params.data.test_gen_skill_type_id === 4803 ? 10 : 0,
      });

      return { data };
    }

    case DELETE: {
      await api.delete(`/support/db/test_gen_skills/${params.id}`);

      const {
        data: { data: position_skill_jt },
      } = await api.get(`/support/db/test_gen_position_test_gen_skill_jt`, {
        params: {
          offset: 0,
          test_gen_skill_id: params.id,
        },
      });

      await Promise.all(
        position_skill_jt.map(({ id }) =>
          api.delete(`/support/db/test_gen_position_test_gen_skill_jt/${id}`)
        )
      );

      return { data: params.previousData };
    }

    case DELETE_MANY: {
      await Promise.all(
        params.ids.map(id => api.delete(`/support/db/test_gen_skills/${id}`))
      );

      const {
        data: { data: position_skill_jt },
      } = await api.get(`/support/db/test_gen_position_test_gen_skill_jt`, {
        params: {
          offset: 0,
          'test_gen_skill_id.in': params.ids.join(),
        },
      });

      await Promise.all(
        position_skill_jt.map(({ id }) =>
          api.delete(`/support/db/test_gen_position_test_gen_skill_jt/${id}`)
        )
      );

      return { data: params.ids };
    }

    case UPDATE: {
      return defaultDataProvider(type, 'test_gen_skills', params);
    }

    default:
      return defaultDataProvider(type, 'test_gen_skills', params);
  }
}

const isEmptyObject = value => isPlainObject(value) && isEmpty(value);
