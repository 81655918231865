import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  BooleanField,
} from 'react-admin';

export const ExpertList = props => {
  return (
    <List title="Expert Accounts" {...props}>
      <Datagrid rowClick="show">
        <TextField source="id" label="ID" />
        <ReferenceField
          label="Name"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <TextField source="name" label="Name" />
        </ReferenceField>
        <ReferenceField
          label="Email"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Verified"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <BooleanField source="is_email_verified" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
