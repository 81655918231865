import React from 'react';
import {
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  NumberInput,
  required,
  minValue,
} from 'react-admin';
import { ExperienceLevelInput } from './ExperienceLevelInput';

export const PositionCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="Position Type"
        source="test_gen_position_type_id"
        reference="test_gen_position_types"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ExperienceLevelInput
        source="experience_level"
        label="Experience level"
      />
      <BooleanInput source="is_premium" label="Premium" defaultValue={false} />
      <BooleanInput source="is_hidden" label="Hidden" defaultValue={true} />
      <NumberInput
        source="order_weight"
        label="Order weight"
        defaultValue={0}
        validate={[required(), minValue(0)]}
      />
    </SimpleForm>
  </Create>
);
