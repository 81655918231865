import React from 'react';
import {
  Button,
  downloadCSV,
  useDataProvider,
  useUnselectAll,
} from 'react-admin';
import DownloadIcon from '@material-ui/icons/GetApp';
import jsonExport from 'jsonexport/dist';
import get from 'lodash/get';

export const WorkspaceExportButton = props => {
  const dataProvider = useDataProvider();
  const unselectAll = useUnselectAll();

  const handleClick = async () => {
    const { data: workspaces } = await dataProvider.getMany('workspaces', {
      ids: props.selectedIds,
    });

    const data = prepareData(workspaces);

    jsonExport(data, (err, csv) => {
      downloadCSV(csv, 'workspaces');
    });

    unselectAll(props.resource);
  };

  return (
    <Button label="Export" onClick={() => void handleClick()}>
      <DownloadIcon />
    </Button>
  );
};

const prepareData = workspaces => {
  return workspaces.map(workspace => {
    return {
      id: workspace.id,
      name: workspace.name,
      signed_up: workspace.created_at,
      subscription_plan: workspace.subscription_plan,
      last_seen: workspace.stats.last_used,
      clearbit_industry: get(workspace.clearbit, 'company.category.industry'),
      clearbit_country_code: get(workspace.clearbit, 'company.geo.countryCode'),
      clearbit_state_code: get(workspace.clearbit, 'company.geo.stateCode'),
      clearbit_city: get(workspace.clearbit, 'company.geo.city'),
      clearbit_employees_range: get(
        workspace.clearbit,
        'company.metrics.employeesRange'
      ),
      clearbit_seniority: get(
        workspace.clearbit,
        'person.employment.seniority'
      ),
      email_verified: workspace.stats.is_owner_email_verified,
      published_job_openings: workspace.stats.total_published_job_openings,
      closed_job_openings: workspace.stats.total_closed_job_openings,
      users: workspace.stats.total_workspace_users,
      candidates: workspace.stats.total_test_takers,
      visitors: workspace.stats.total_visitors,
      time_spent_minutes: Math.round(workspace.stats.usage_time / MINUTE),
      sessions: workspace.stats.num_sessions,
    };
  });
};

const SECOND = 1000;
const MINUTE = SECOND * 60;
