import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  minValue,
} from 'react-admin';

export const SkillTypeForm = ({
  hasList,
  hasEdit,
  hasShow,
  hasCreate,
  ...props
}) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" validate={required()} />
      <NumberInput
        source="order_weight"
        label="Order weight"
        defaultValue={0}
        validate={[required(), minValue(1)]}
      />
      <BooleanInput source="is_hidden" label="Hidden" defaultValue={false} />
      <ReferenceInput
        label="Skill Category"
        source="category_id"
        reference="test_gen_skill_categories"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  );
};
