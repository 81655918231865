import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

export const PainPointField = ({ source, record = {} }) => {
  const value = get(record, source);

  if (value == null) {
    return (
      <Typography component="span" variant="body1">
        Not available
      </Typography>
    );
  } else if (value === 'more-candidates') {
    return (
      <Typography component="span" variant="body1">
        Sourcing
      </Typography>
    );
  } else if (value === 'better-candidates') {
    return (
      <Typography component="span" variant="body1">
        Screening
      </Typography>
    );
  } else {
    return null;
  }
};

PainPointField.defaultProps = {
  addLabel: true,
};
