import api from './api';

export async function uploadImage(source, type) {
  const requestData = new FormData();
  requestData.append('upload', source);

  const response = await api.post('/support/images', requestData, {
    params: { type },
  });

  return response.data.url;
}

export const parseImage = url => (url ? { src: url } : null);

export const serializeImage = async image => {
  if (image == null) {
    return '';
  }

  if (image.rawFile != null) {
    const url = await uploadImage(image.rawFile);
    return url;
  } else {
    return image.src;
  }
};
