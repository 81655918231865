import React from 'react';
import { Show, TextField, SimpleShowLayout } from 'react-admin';
import { EmailTemplatesTitle } from './EmailTemplatesTitle';

export const EmailTemplatesShow = props => (
  <Show title={<EmailTemplatesTitle />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="template_type" label="Type" />
      <TextField source="kind" label="Kind" />
      <TextField source="status" label="Status" />
      <TextField source="subject" label="Subject" />
      <TextField source="message" label="Message" />
    </SimpleShowLayout>
  </Show>
);
