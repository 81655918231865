import React from 'react';
import { Button } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';

export const CandidatesButton = ({ record = {} }) => (
  <Button
    size="small"
    color="primary"
    label="Candidates"
    component={Link}
    to={{
      pathname: '/candidates',
      search: stringify({
        page: 1,
        perPage: 10,
        filter: JSON.stringify({ job_opening_id: record.id }),
      }),
    }}
  >
    <PersonIcon />
  </Button>
);
