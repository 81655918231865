import React from 'react';
import { Create } from 'react-admin';
import { SkillTypeForm } from './SkillTypeForm';

export const SkillTypeCreate = props => {
  return (
    <Create title="Create skill type" {...props}>
      <SkillTypeForm {...props} />
    </Create>
  );
};
