import React from 'react';
import get from 'lodash/get';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  image: {
    maxHeight: '30px',
    maxWidth: '80px',
  },
});

export const ImageField = ({ className, classes, record, source }) => {
  const value = get(record, source);

  return (
    <div className={className}>
      <img src={value} className={classes.image} alt="alt" />
    </div>
  );
};

export default withStyles(styles)(ImageField);
