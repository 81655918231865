import React from 'react';
import { map } from 'lodash';
import {
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  BooleanInput,
  FormDataConsumer,
  ReferenceInput,
  required,
} from 'react-admin';
import { OptionAnswerInput } from '../inputs/option_answer';
import { TextAnswerInput } from '../inputs/text_answer';
import { MarkdownInput } from '../inputs/markdown_input';
import types from './types';

const questionTypeChoices = map(types, (name, id) => ({ name, id }));

const answerInputs = {
  'single-choice': <OptionAnswerInput source="options" type="single-choice" />,
  'multiple-choice': (
    <OptionAnswerInput source="options" type="multiple-choice" />
  ),
  'picture-question': (
    <OptionAnswerInput source="options" type="picture-question" />
  ),
  'numeric-input': (
    <TextAnswerInput source="options" type="number" label="Answer" />
  ),
};

const difficultyChoices = [
  { id: 'standard', name: 'Standard' },
  { id: 'hard', name: 'Hard' },
];
const testTypeChoices = [
  { id: 'quiz', name: 'Quiz' },
  { id: 'video', name: 'Video' },
  { id: 'homework', name: 'Homework' },
  { id: 'interview', name: 'Interview' },
];

export const QuestionForm = props => (
  <SimpleForm {...props} redirect="show">
    <SelectInput
      source="question_type"
      choices={questionTypeChoices}
      validate={required()}
    />
    <ReferenceInput
      source="test_gen_skill_id"
      reference="test_gen_skills"
      label="Skill"
      validate={required()}
      perPage={1000}
      sort={{ field: 'skill', order: 'ASC' }}
    >
      <SelectInput optionText="skill" optionValue="id" />
    </ReferenceInput>
    <TextInput source="title" />
    <MarkdownInput source="description" validate={required()} />
    <MarkdownInput source="notes" />
    <FormDataConsumer>
      {({ formData, ...props }) => {
        const input = answerInputs[formData.question_type];
        return input ? React.cloneElement(input, props) : null;
      }}
    </FormDataConsumer>
    <NumberInput source="points" defaultValue={1} validate={required()} />
    <BooleanInput source="is_reviewed" label="Reviewed" />
    <BooleanInput source="is_example" label="Example" />
    <NumberInput
      source="duration_in_seconds"
      defaultValue={30}
      validate={required()}
    />
    <SelectInput
      source="difficulty"
      choices={difficultyChoices}
      validate={required()}
    />
    <SelectInput source="test_type" choices={testTypeChoices} />
  </SimpleForm>
);
