import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Content,
  PictureOption,
  PictureOptionsAnswer,
  TextAnswer,
  TextOption,
  TextOptionsAnswer,
} from '@hundred5/design-system';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles({
  option: {
    margin: '-10px 0',
  },
});

export const QuestionAnswer = ({ record }) => {
  const classes = useStyles();

  if (record == null) return null;

  switch (record.question_type) {
    case 'free-text':
    case 'code-input':
      return <TextAnswer placeholder="Write answer here..." disabled />;

    case 'numeric-input':
      return (
        <TextAnswer
          placeholder="Write answer here..."
          value={get(record, 'options[0].content')}
          disabled
        />
      );

    case 'single-choice':
    case 'multiple-choice':
      return (
        <TextOptionsAnswer>
          {sortOptions(record.options).map(option => (
            <TextOption
              key={option.id}
              name="question-option"
              id={`question-option-${option.id}`}
              type={optionTypes[record.question_type]}
              selected={option.points > 0}
              disabled
            >
              <Content>
                <ReactMarkdown
                  source={option.content}
                  className={classes.option}
                />
              </Content>
            </TextOption>
          ))}
        </TextOptionsAnswer>
      );

    case 'picture-question':
      return (
        <PictureOptionsAnswer>
          {sortOptions(record.options).map(option => (
            <PictureOption
              key={option.id}
              name="question-option"
              id={`question-option-${option.id}`}
              selected={option.points > 0}
              url={option.content}
              disabled
            />
          ))}
        </PictureOptionsAnswer>
      );

    default:
      return null;
  }
};

const optionTypes = {
  'single-choice': 'single',
  'multiple-choice': 'multiple',
};

const sortOptions = options => sortBy(options, ['order_weight']);
