import React from 'react';
import { SelectInput } from 'react-admin';

export const ExperienceLevelInput = props => (
  <SelectInput
    initialValue=""
    emptyValue=""
    allowEmpty
    choices={[
      { id: 'junior', name: 'Junior' },
      { id: 'mid-level', name: 'Mid-level' },
      { id: 'senior', name: 'Senior' },
    ]}
    {...props}
  />
);
