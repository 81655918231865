import React from 'react';
import { FunctionField, Labeled } from 'react-admin';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

export const StringField = props => (
  <Labeled label={props.label}>
    <FunctionField {...props} render={() => props.value} />
  </Labeled>
);

export const BoolField = ({ label, value }) => (
  <Labeled label={label}>{value ? <DoneIcon /> : <ClearIcon />}</Labeled>
);
