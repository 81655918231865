import React from 'react';
import { Button, useNotify } from 'react-admin';
import CreateIcon from '@material-ui/icons/Create';
import createAssessment from '../../api/assessments';

export const CreateAssessmentButton = props => {
  const notify = useNotify();
  const handleClick = async () => {
    if (!props.url) {
      notify(`Error: Social Meta Image Url is missing`, { type: 'error' });
      return null;
    }

    const response = await createAssessment({
      skill_id: props.skillId,
      social_meta_image_url: props.url,
    });

    if (response.status === 201) {
      notify(`Assessment has been successfully created`, { type: 'info' });
    } else {
      notify(
        `Error: ${response.data.error.error} - Error ID: ${
          response.headers['th-request-id']
        }`,
        { type: 'error' }
      );
    }
  };

  return (
    <Button
      label="Create assessment from this skill"
      onClick={() => void handleClick()}
    >
      <CreateIcon />
    </Button>
  );
};
