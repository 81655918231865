import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const useStyles = makeStyles(theme => ({
  button: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const PositionNameCreateButton = ({ record }) => {
  const classes = useStyles();

  return (
    <div className={classes.button}>
      <Button
        color="primary"
        component={Link}
        to={`/test_gen_position_names/create?source={"test_gen_position_id":${record.id}}`}
        label="Add name"
      >
        <AddIcon />
      </Button>
    </div>
  );
};

export default PositionNameCreateButton;
