import React from 'react';
import { Datagrid, DateField, FunctionField, TextField } from 'react-admin';
import { ClearbitField } from './ClearbitField';
import { CompanyField } from '../fields';
import { UsageInfoField } from './UsageInfoField';
import { StatsField } from './StatsField';
import { ContactDataField } from './ContactDataField';
import { getPriority } from '../../utils/priorities';

export const WorkspaceGrid = props => {
  return (
    <Datagrid {...props} rowClick="show">
      <DateField source="created_at" label="Signed up" />
      <CompanyField source="name" label="Name" />
      <FunctionField
        source="priority"
        label="Priority"
        render={record => getPriority(record)}
      />
      <TextField source="subscription_plan" label="Plan" />
      <ClearbitField source="clearbit" label="Clearbit" sortable={false} />
      <StatsField label="Stats" sortable={false} />
      <UsageInfoField label="Usage" />
      <ContactDataField label="HubSpot" />
    </Datagrid>
  );
};
