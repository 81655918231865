import React from 'react';

const WorkspaceTitle = ({ record }) =>
  record ? (
    <span>
      Workspace #{record.id} - {record.name}
    </span>
  ) : null;

export default WorkspaceTitle;
