import React from 'react';
import { Button } from 'react-admin';
import PageviewIcon from '@material-ui/icons/Pageview';
import configuration from '../../configuration';

export const PreviewButton = ({ record = {} }) => (
  <Button
    size="small"
    color="primary"
    label="Preview"
    component="a"
    href={`${configuration.candidate.url}/preview/${record.slug}?token=${record.preview_token}`}
    target="_blank"
  >
    <PageviewIcon />
  </Button>
);
