import React from 'react';
import { TextInput } from 'react-admin';

const format = state => {
  if (state == null) return '';

  const [option] = state;
  return option ? option.content : '';
};

const parse = value => [
  {
    points: 1,
    content: value,
    order_weight: 0,
  },
];

export const TextAnswerInput = props => (
  <TextInput {...props} parse={parse} format={format} />
);
