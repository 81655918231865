import React, { useState } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  ReferenceField,
  FunctionField,
} from 'react-admin';
import SkillTitle from './SkillTitle';
import { CreateAssessmentButton } from './CreateAssessmentButton';
import { TextInput } from '@hundred5/design-system';

export const SkillShow = props => {
  const [url, setUrl] = useState();
  const [dirty, setDirty] = useState(false);

  return (
    <Show title={<SkillTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source="skill" label="Name" />
        <ReferenceField
          label="Type"
          source="test_gen_skill_type_id"
          reference="test_gen_skill_types"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_hidden" label="Hidden" />
        <BooleanField source="is_premium" label="Premium" />
        <FunctionField
          label="Archived"
          render={record => (
            <BooleanDateField record={record} source="archived_at" />
          )}
        />
        <TextField label="Social Meta Image Url" />
        <TextInput
          error={dirty && url === ''}
          onChange={event => {
            if (!dirty) {
              setDirty(true);
            }
            setUrl(event.target.value);
          }}
        ></TextInput>
        <FunctionField
          label=" "
          render={record => (
            <CreateAssessmentButton skillId={record.id} url={url} />
          )}
        ></FunctionField>
      </SimpleShowLayout>
    </Show>
  );
};

const BooleanDateField = ({ record = {}, source }) => {
  let fieldRecord = { ...record };
  fieldRecord[source] = !!record[source];
  return <BooleanField record={fieldRecord} source={source} />;
};
