import { GET_LIST, GET_MANY, GET_ONE } from 'react-admin';
import api from './api';
import { getCommonParams } from './utils';
import { stringify } from 'query-string';

const parse = data => ({
  ...data,
  test_takers: {
    ...data.test_takers,
  },
});

export default async function(type, params) {
  switch (type) {
    case GET_ONE: {
      const response = await api.get(`/backstage/candidates/${params.id}`);
      return { data: parse(response.data) };
    }

    case GET_LIST: {
      const { pagination, filter, sort } = params;

      const query = {
        ...getCommonParams(pagination, sort),
        ...filter,
      };

      const response = await api.get('/backstage/candidates', {
        params: query,
      });

      const { total, candidates } = response.data;

      return {
        data: candidates ? candidates.map(parse) : [],
        total: total,
      };
    }

    case GET_MANY: {
      if (params.ids.length === 0) return { data: [], total: 0 };

      const response = await api.get('/backstage/candidates', {
        params: { id: params.ids },
        paramsSerializer: params => stringify(params, { arrayFormat: 'none' }),
      });

      const { candidates } = response.data;

      return {
        data: candidates ? candidates.map(parse) : [],
      };
    }

    default:
      throw new Error(`Unsupported request: ${type}`);
  }
}
