import React from 'react';
import {
  List,
  Datagrid,
  Filter,
  TextField,
  BooleanField,
  SelectInput,
  TextInput,
  ReferenceField,
  ReferenceInput,
  BooleanInput,
  DeleteButton,
} from 'react-admin';

export const SkillList = props => {
  return (
    <List
      title="Skills"
      filters={<SkillFilter />}
      sort={{ field: 'skill', order: 'ASC' }}
      {...props}
    >
      <Datagrid rowClick="show">
        <TextField source="skill" label="Name" />
        <ReferenceField
          label="Type"
          source="test_gen_skill_type_id"
          reference="test_gen_skill_types"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="is_hidden" label="Hidden" />
        <BooleanField source="is_premium" label="Premium" />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

const SkillFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="skill.like" alwaysOn />
    <ReferenceInput
      label="Type"
      source="test_gen_skill_type_id"
      reference="test_gen_skill_types"
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <BooleanInput label="Hidden" source="is_hidden" />
  </Filter>
);
