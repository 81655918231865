import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import yellow from '@material-ui/core/colors/yellow';
import { ChipList } from '../common';

export const UsageInfoField = props => {
  const classes = useStyles(props);

  const { usage_time, num_sessions, last_seen } = props.record.stats;
  const { created_at } = props.record;

  return (
    <ChipList>
      <Chip
        label={`LAST: ${last_seen ? last_seen.toLocaleDateString() : '?'}`}
        size="small"
      />
      <Chip
        className={classes.timeSpent}
        label={`TIME: ${
          usage_time != null ? formatTimeSpent(usage_time) : '?'
        }`}
        size="small"
      />
      <Chip
        className={classes.sessions}
        label={`SESS: ${num_sessions != null ? num_sessions : '?'}`}
        size="small"
      />
      <Chip
        className={classes.timeSpent}
        label={`AVG TIME/DAY: ${
          usage_time != null
            ? (usage_time / getDaysSinceCreatedAt(created_at) / 60).toFixed(2)
            : '?'
        }MIN`}
        size="small"
      />
    </ChipList>
  );
};

UsageInfoField.defaultProps = {
  addLabel: true,
};

const useStyles = makeStyles(theme => ({
  timeSpent: props =>
    getRangeStyle(props.record.stats.usage_time, [10 * MINUTE, 30 * MINUTE]),
  sessions: props => getRangeStyle(props.record.stats.num_sessions, [2, 6]),
}));

const getRangeStyle = (value, thresholds) => {
  if (value == null) return grey[300];

  if (value < thresholds[0]) {
    return { backgroundColor: grey[300] };
  } else if (value < thresholds[1]) {
    return { backgroundColor: yellow[300] };
  } else {
    return { backgroundColor: green[300] };
  }
};

const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

const formatTimeSpent = value => {
  if (value < HOUR) {
    return `${(value / MINUTE).toFixed(0)}MIN`;
  } else {
    return `${(value / HOUR).toFixed(1)}HR`;
  }
};

const getDaysSinceCreatedAt = value => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const createdAt = new Date(value);
  const now = new Date();
  const days = Math.round(Math.abs((createdAt - now) / oneDay));

  if (days === 0) {
    return 1;
  }

  return days;
};
