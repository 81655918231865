import React from 'react';
import { Edit } from 'react-admin';
import { EmailTemplatesTitle } from './EmailTemplatesTitle';
import { EmailTemplatesForm } from './EmailTemplatesForm';

export const EmailTemplatesEdit = props => (
  <Edit title={<EmailTemplatesTitle />} {...props}>
    <EmailTemplatesForm {...props} />
  </Edit>
);
