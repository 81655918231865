function createConfig() {
  switch (process.env.REACT_APP_TARGET_ENV) {
    case 'production':
      return {
        api: {
          url: 'https://api.hire.toggl.com/api/v1',
        },
        admin: {
          url: 'https://alt.hire.toggl.com',
        },
        candidate: {
          url: 'https://apply.hire.toggl.com',
        },
        recurly: {
          url: 'https://hundred5.recurly.com',
        },
      };

    case 'staging':
      return {
        api: {
          url: 'https://api.hire.toggl.space/api/v1',
        },
        admin: {
          url: 'https://hire.toggl.space',
        },
        candidate: {
          url: 'https://apply.hire.toggl.space',
        },
        recurly: {
          url: 'https://hundred5-test.recurly.com',
        },
      };

    case 'development':
    default:
      return {
        api: {
          url: 'https://api.hire.toggl.space/api/v1',
        },
        admin: {
          url: 'http://localhost:3000',
        },
        candidate: {
          url: 'http://localhost:3001',
        },
        recurly: {
          url: 'https://hundred5-test.recurly.com',
        },
      };
  }
}

export default createConfig();
