import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  BooleanField,
  NumberField,
} from 'react-admin';

export const PositionCategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" label="Name" />
      <NumberField source="order_weight" label="Order weight" />
      <BooleanField source="is_hidden" label="Hidden" />
    </SimpleShowLayout>
  </Show>
);
