import React from 'react';
import { Edit } from 'react-admin';
import PositionNameForm from './PositionNameForm';

export const PositionNameEdit = props => {
  return (
    <Edit title="Edit position name" {...props}>
      <PositionNameForm {...props} />
    </Edit>
  );
};
