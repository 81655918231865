import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  ShowButton,
  BooleanField,
  ChipField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  NullableBooleanInput,
} from 'react-admin';

export const UserList = props => (
  <List title="Users" filters={<UserFilter />} {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="email" label="E-mail" />
      <BooleanField source="is_email_verified" label="Verified" />
      <ReferenceManyField
        label="Workspaces"
        reference="workspace_user_jt"
        target="user_id"
        sortable={false}
      >
        <SingleFieldList>
          <ReferenceField
            source="workspace_id"
            reference="workspaces"
            link="show"
          >
            <ChipField source="name" />
          </ReferenceField>
        </SingleFieldList>
      </ReferenceManyField>
      <ShowButton />
    </Datagrid>
  </List>
);

const UserFilter = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" alwaysOn />
    <TextInput label="Search by email" source="email.like" alwaysOn />
    <TextInput label="Search by name" source="name.like" alwaysOn />
    <NullableBooleanInput label="Verified" source="is_email_verified" />
  </Filter>
);
