import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DeleteButton,
} from 'react-admin';

export const AdminUserList = props => {
  return (
    <List title="Admin Users" {...props}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <ReferenceField
          source="user_id"
          reference="users"
          label="Email"
          link="show"
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          source="user_id"
          reference="users"
          label="Name"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <DeleteButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};
