import React, { useState } from 'react';
import {
  TabbedShowLayout,
  FunctionField,
  Labeled,
  Link,
  Tab,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TestTakerTestsField from './TestTakerTests';
import { StringField, BoolField } from './CommonFields';

const TestTakerField = props => {
  const testTakers = Object.entries(props.record.test_takers).map(e => e[1]);

  return (
    <TestTakers>
      {testTakers.map(testTaker => (
        <TestTaker
          key={testTaker.id}
          testTaker={testTaker}
          {...props}
        ></TestTaker>
      ))}
    </TestTakers>
  );
};

export default TestTakerField;

const TestTaker = props => {
  const [open, setOpen] = useState(false);

  const testTaker = props.testTaker;
  const contactInfo = testTaker.contact_info;
  const classes = useStyles();
  return (
    <Card className={classes.container}>
      <div className={classes.rowHeader}>
        <div>
          <Labeled label="Created At" className={classes.createdAt}>
            <FunctionField
              render={() => {
                return new Date(testTaker.created_at).toLocaleDateString();
              }}
            />
          </Labeled>
          <Labeled label="Job Opening ID">
            <FunctionField
              render={() => (
                <Link to={`/job_openings/${testTaker.job_opening_id}/show`}>
                  {testTaker.job_opening_id}
                </Link>
              )}
            />
          </Labeled>
        </div>
        <MuiButton color="primary" onClick={() => setOpen(!open)}>
          {open ? 'Close' : 'Open'}
        </MuiButton>
      </div>
      {open && (
        <TabbedShowLayout>
          <Tab label="Summary">
            <StringField label="Test Taker ID" value={testTaker.id} />
            <StringField label="Name" value={testTaker.name} />
            <StringField
              label="Candidate Fields"
              value={testTaker.candidate_fields}
            />
            <StringField
              label="Candidate State"
              value={testTaker.candidate_state}
            />
            <StringField label="Category ID" value={testTaker.category_id} />
            <StringField label="Created At" value={testTaker.created_at} />
            <StringField label="Email" value={testTaker.email} />
            <StringField label="Hire State" value={testTaker.hire_state} />
            <BoolField label="Demo" value={testTaker.is_demo} />
            <BoolField label="Evaluated" value={testTaker.is_evaluated} />
            <BoolField label="Suspicious" value={testTaker.is_suspicious} />
            <BoolField
              label="Video Intro Unlocked?"
              value={testTaker.is_video_intro_unlocked}
            />
            <StringField label="Rating" value={testTaker.rating} />
            <BoolField label="Seen" value={testTaker.seen} />
            <StringField label="URL" value={testTaker.url} />
            <BoolField
              label="User Marked Suspicious"
              value={testTaker.user_marked_suspicious}
            />
            <StringField
              label="User Updated At"
              value={testTaker.user_updated_at}
            />
            <BoolField label="Unlocked?" value={testTaker.is_unlocked} />
            <Labeled label="Job Opening ID">
              <FunctionField
                render={() => (
                  <Link to={`/job_openings/${testTaker.job_opening_id}/show`}>
                    {testTaker.job_opening_id}
                  </Link>
                )}
              />
            </Labeled>
          </Tab>

          <Tab label="Contact Info">
            <StringField label="Full Name" value={contactInfo.full_name} />
            <StringField label="Phone" value={contactInfo.phone} />
            <StringField
              label="Contact Email"
              value={contactInfo.contact_email}
            />
            <StringField label="Country" value={contactInfo.country} />
            <StringField label="City" value={contactInfo.city} />
            <StringField label="State" value={contactInfo.state} />
            <StringField label="Street" value={contactInfo.street} />
            <StringField label="Zip Code" value={contactInfo.zip_code} />
            <StringField label="Website" value={contactInfo.website} />
            <StringField label="LinkedIn" value={contactInfo.linkedin} />
            <StringField label="Github" value={contactInfo.github} />
          </Tab>
          <Tab label="Tests">
            <TestTakerTestsField tests={testTaker.tests} />
          </Tab>
        </TabbedShowLayout>
      )}
    </Card>
  );
};

const TestTakers = props => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(-0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
    padding: '12px',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  rowHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  createdAt: {
    marginRight: '20px',
  },
}));
