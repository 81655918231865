import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Filter,
  TextInput,
} from 'react-admin';
import { LinkToExpertQuestions } from '../common/LinkToExpertQuestions';

export const ExpertQuestionList = props => {
  return (
    <List
      title="Expert Questions"
      filters={<ExpertQuestionsFilters />}
      {...props}
    >
      <Datagrid
        rowClick={(id, _, record) =>
          `/questions/${record.test_gen_question_id}/show`
        }
      >
        <TextField source="id" label="ID" />
        <TextField source="expert_question_batch_id" label="Batch ID" />
        <ReferenceField
          source="expert_question_batch_id"
          reference="expert_question_batches"
          label="Batch name"
          link={false}
        >
          <ReferenceField
            source="expert_challenge_id"
            reference="expert_challenges"
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </ReferenceField>
        <LinkToExpertQuestions />
      </Datagrid>
    </List>
  );
};

const ExpertQuestionsFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by ID" source="id" />
    <TextInput label="Search by batch ID" source="expert_question_batch_id" />
  </Filter>
);
