import React from 'react';
import { Button } from 'react-admin';
import AssignmentIcon from '@material-ui/icons/Assignment';
import configuration from '../../configuration';

export const ApplyButton = ({ record = {} }) => (
  <Button
    size="small"
    color="primary"
    label="Apply"
    component="a"
    href={`${configuration.candidate.url}/${record.slug}`}
    target="_blank"
  >
    <AssignmentIcon />
  </Button>
);
