import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import get from 'lodash/get';

const CompanyField = ({ record = {} }) => {
  const logoUrl = get(record, 'logo_url');
  const name = get(record, 'name');
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Avatar
        src={logoUrl}
        alt={name}
        classes={{ root: classes.avatar, img: classes.img }}
        variant="square"
      >
        {name.slice(0, 1).toUpperCase()}
      </Avatar>

      <Typography variant="body2" className={classes.name}>
        {name}
      </Typography>
    </div>
  );
};

CompanyField.defaultProps = {
  addLabel: true,
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    width: 30,
    height: 30,
  },
  img: {
    objectFit: 'contain',
  },
  name: {
    whiteSpace: 'nowrap',
  },
}));

export default CompanyField;
