import React from 'react';
import { Edit } from 'react-admin';
import { SkillTypeForm } from './SkillTypeForm';

export const SkillTypeEdit = props => {
  return (
    <Edit title="Edit skill type" {...props}>
      <SkillTypeForm {...props} />
    </Edit>
  );
};
