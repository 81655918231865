import React from 'react';
import { Create } from 'react-admin';
import { PositionCategoryForm } from './PositionCategoryForm';

export const PositionCategoryCreate = props => {
  return (
    <Create title="Create position category" {...props}>
      <PositionCategoryForm {...props} />
    </Create>
  );
};
