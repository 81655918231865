import React from 'react';
import ArchiveIcon from '@material-ui/icons/Archive';
import UndoIcon from '@material-ui/icons/Undo';
import { Button, useUpdate } from 'react-admin';

export const ArchiveButton = ({ record = {}, resource }) => {
  const archived = record.archived_at != null;
  const diff = { archived_at: !archived ? new Date().toISOString() : null };
  const [update, { loading }] = useUpdate(resource, record.id, diff, record);

  return (
    <Button
      size="small"
      color="primary"
      label={!archived ? 'Archive' : 'Restore'}
      disabled={loading}
      onClick={update}
    >
      {!archived ? <ArchiveIcon /> : <UndoIcon />}
    </Button>
  );
};
