import React from 'react';

import { List, Datagrid, TextField } from 'react-admin';

export const EmailTemplatesList = props => (
  <List title="Email Templates" {...props}>
    <Datagrid rowClick="show">
      <TextField source="id" label="ID" />
      <TextField source="name" label="Name" />
      <TextField source="template_type" label="Type" />
      <TextField source="kind" label="Kind" />
      <TextField source="status" label="Status" />
    </Datagrid>
  </List>
);
