import React from 'react';
import { SelectArrayInput } from 'react-admin';
import { getCountryChoices } from '../../utils/countries';

const choices = getCountryChoices();

const CountriesInput = props => (
  <SelectArrayInput choices={choices} {...props} />
);

export default CountriesInput;
