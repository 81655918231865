import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useMutation, Button } from 'react-admin';

export const FavoriteButton = ({ record = {}, resource }) => {
  const favorite = record.question && record.question.favorite;

  const [update, { loading }] = useMutation({
    type: 'update',
    resource,
    payload: {
      id: record.id,
      data: {
        question: {
          ...record.question,
          favorite: !favorite,
        },
      },
    },
  });

  return (
    <Button
      size="small"
      color="primary"
      label={favorite ? 'Unfavorite' : 'Favorite'}
      disabled={loading}
      onClick={e => {
        e.stopPropagation();
        update();
      }}
    >
      {favorite ? <StarIcon /> : <StarBorderIcon />}
    </Button>
  );
};
