import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = {
  link: {
    color: '#3599CE',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export const Link = withStyles(styles)(
  ({ classes, component = 'a', ...props }) => (
    <Typography
      component={component}
      variant="body1"
      className={classes.link}
      {...props}
    />
  )
);
