import React from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import { withStyles } from '@material-ui/core/styles';
import { Button } from 'react-admin';

const styles = {
  button: {
    marginTop: '1em',
    marginBottom: '1em',
  },
};

const NotificationCreateButton = ({ classes, record }) => {
  return (
    <Button
      className={classes.button}
      component={Link}
      to={`/notification_settings/create?workspace_id=${record.id}&workspace_name=${record.name}`}
      label="Add"
    >
      <AddIcon />
    </Button>
  );
};

export default withStyles(styles)(NotificationCreateButton);
