export const getCommonParams = (pagination, sort) => {
  const params = {};

  if (pagination != null) {
    const { page, perPage } = pagination;

    if (perPage != null) {
      params['limit'] = perPage;
    }

    if (page != null && perPage != null) {
      params['offset'] = (page - 1) * perPage;
    }
  }

  if (sort != null) {
    const { field, order } = sort;

    if (field != null) {
      params['sort-by'] = field;
    }

    if (order != null) {
      params['sort-order'] = order.toLowerCase();
    }
  }

  return params;
};
