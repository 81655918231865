import React from 'react';
import get from 'lodash/get';
import Typography from '@material-ui/core/Typography';

export const JobLocationField = ({ source, record = {} }) => {
  const location = get(record, source);

  if (location == null) {
    return (
      <Typography component="span" variant="body1">
        Not available
      </Typography>
    );
  } else if (location.type === 'remote') {
    return (
      <Typography component="span" variant="body1">
        Remote
      </Typography>
    );
  } else if (location.type === 'locally') {
    return (
      <Typography component="span" variant="body1">
        Local: {location.country}, {location.relocate ? 'open' : 'not open'} to
        relocate
      </Typography>
    );
  } else {
    return null;
  }
};

JobLocationField.defaultProps = {
  addLabel: true,
};
