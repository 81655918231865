import React from 'react';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button } from 'react-admin';

export const AddToLibraryButton = props => {
  return (
    <Button
      size="small"
      color="primary"
      label="Add to library"
      onClick={e => {
        e.stopPropagation();
        props.onClick();
      }}
    >
      <AddCircleIcon />
    </Button>
  );
};
