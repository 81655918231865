import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
  ShowButton,
  Filter,
  BooleanInput,
} from 'react-admin';
import { truncate } from 'lodash';
import { FavoriteField } from './FavoriteField';

export const SubmittedQuestionList = props => (
  <List
    title="Submitted questions"
    sort={{ field: 'created_at', order: 'DESC' }}
    filters={<SubmittedQuestionFilter />}
    filterDefaultValues={{ is_archived: false }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid rowClick="show">
      <FavoriteField source="question.favorite" label="Fav" />
      <DateField source="created_at" label="Submitted" />
      <ReferenceField
        source="test_taker_id"
        reference="candidates"
        label="Candidate"
        sortable={false}
        link="show"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="question.topic" label="Topic" sortable={false} />
      <FunctionField
        label="Text"
        render={record => truncate(record.question.text, { length: 50 })}
      />
      <ShowButton />
    </Datagrid>
  </List>
);

const SubmittedQuestionFilter = props => (
  <Filter {...props}>
    <BooleanInput source="is_archived" label="Archived" alwaysOn />
  </Filter>
);
