import React from 'react';
import { Create, SelectInput, SimpleForm, ReferenceInput } from 'react-admin';
import { parse } from 'query-string';

const NotificationSettingsCreate = props => {
  const { workspace_id, workspace_name } = parse(props.location.search);
  const redirect = workspace_id ? `/workspaces/${workspace_id}/show/4` : false;

  return (
    <Create
      {...props}
      title={`Create notification settings for ${workspace_name} workspace`}
    >
      <SimpleForm redirect={redirect}>
        <ReferenceInput
          source="job_opening_id"
          reference="job_openings"
          filter={{ workspace_id }}
          required
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="user_id"
          reference="users"
          filter={{ workspace_id }}
          required
        >
          <SelectInput optionText="email" optionValue="id" />
        </ReferenceInput>

        <SelectInput
          source="email_notifications_state"
          required
          choices={[
            { id: 'all', name: 'All' },
            { id: 'possible-hires', name: 'Possible hires' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default NotificationSettingsCreate;
