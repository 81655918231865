import React from 'react';
import {
  Pagination,
  Show,
  TabbedShowLayout,
  Tab,
  Datagrid,
  TopToolbar,
  TextField,
  DateField,
  NumberField,
  ReferenceManyField,
  FunctionField,
  ImageField,
  EditButton,
  ReferenceField,
  ShowButton,
  DeleteButton,
} from 'react-admin';
import { LinkField, JSONField } from '../fields';
import { CandidatesButton } from '../job_openings/CandidatesButton';
import { LogInButton } from './LogInButton';
import { NotificationSelect } from './NotificationSelect';
import WorkspaceTitle from './WorkspaceTitle';
import NotificationCreateButton from './NotificationCreateButton';
import RecurlyButton from './RecurlyButton';
import { UsageInfoField } from './UsageInfoField';
import { ClearbitField } from './ClearbitField';
import { StatsField } from './StatsField';
import { ContactDataField } from './ContactDataField';

export const WorkspaceShow = props => (
  <Show title={<WorkspaceTitle />} actions={<Actions />} {...props}>
    <TabbedShowLayout>
      <Tab label="Details">
        <TextField source="id" label="ID" />
        <TextField source="name" label="Company" />
        <DateField source="created_at" label="Signed up" />
        <TextField source="subscription_plan" label="Subscription plan" />
        <StatsField label="Stats" />
        <ClearbitField source="clearbit" label="Clearbit" />
        <UsageInfoField label="Usage" />
        <ContactDataField label="HubSpot" />
        <ImageField source="logo_url" label="Logo" />
        <LinkField source="logo_url" label="Logo URL" />
      </Tab>

      <Tab label="Info">
        <JSONField source="clearbit.company" label="Clearbit" />
      </Tab>

      <Tab label="Users">
        <ReferenceManyField
          reference="workspace_user_jt"
          target="workspace_id"
          addLabel={false}
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField label="ID" source="user_id" />
            <DateField source="created_at" label="Added" />
            <ReferenceField source="user_id" reference="users" linkType="show">
              <TextField source="email" label="E-mail" />
            </ReferenceField>
            <ReferenceField source="user_id" reference="users" linkType="show">
              <TextField source="name" label="Name" />
            </ReferenceField>
            <TextField source="workspace_role" label="Role" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>

      <Tab label="Job openings">
        <ReferenceManyField
          reference="job_openings"
          target="workspace_id"
          addLabel={false}
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="name" label="Name" />
            <TextField source="state" label="Status" />
            <ReferenceField
              source="id"
              reference="job_opening_stats"
              label="Candidates"
              link={false}
              sortable={false}
            >
              <NumberField source="test_takers" />
            </ReferenceField>
            <NumberField
              source="unlocked_test_takers"
              label="Unlocked candidates"
            />
            <CandidatesButton />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Notification settings">
        <ReferenceManyField
          reference="notification_settings"
          target="workspace_id"
          addLabel={false}
        >
          <Datagrid>
            <ReferenceField
              label="User email"
              source="workspace_user_jt_id"
              reference="workspace_user_jt"
              link={false}
            >
              <ReferenceField source="user_id" reference="users" link={false}>
                <TextField source="email" />
              </ReferenceField>
            </ReferenceField>
            <ReferenceField
              label="Job opening name"
              source="job_opening_id"
              reference="job_openings"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              label="State"
              render={record => <NotificationSelect record={record} />}
            />
            <DeleteButton undoable={false} redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <NotificationCreateButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const Actions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} resource={resource} />
    <LogInButton record={data} />
    <RecurlyButton record={data} />
  </TopToolbar>
);
