import React from 'react';
import { Create } from 'react-admin';
import PositionNameForm from './PositionNameForm';

export const PositionNameCreate = props => {
  return (
    <Create title="Create position name" {...props}>
      <PositionNameForm {...props} />
    </Create>
  );
};
