import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import React from 'react';

export const LinkToExpertQuestions = ({ record }) =>
  record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/expert_questions',
        search: `filter=${JSON.stringify({
          expert_question_batch_id: record.id,
        })}`,
      }}
    >
      Show questions
    </Button>
  ) : null;
