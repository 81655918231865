import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  Datagrid,
  TextField,
  ReferenceManyField,
  ReferenceField,
  EditButton,
  DeleteButton,
  useEditController,
  useCreateController,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  BooleanField,
  NumberField,
  NumberInput,
  number,
  FormDataConsumer,
} from 'react-admin';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PositionNameCreateButton from './PositionNameCreateButton';

export const PositionShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Position">
        <ReferenceField
          label="Primary Name"
          source="primary_test_gen_position_name_id"
          reference="test_gen_position_names"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Position Type"
          source="test_gen_position_type_id"
          reference="test_gen_position_types"
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField label="Experience level" source="experience_level" />
        <BooleanField label="Premium" source="is_premium" />
        <BooleanField label="Hidden" source="is_hidden" />
        <NumberField source="order_weight" label="Order weight" />
        <TextField label="Description" source="test_template_description" />
      </Tab>
      <Tab label="Skills">
        <ReferenceManyField
          addLabel={false}
          reference="test_gen_position_test_gen_skill_jt"
          target="test_gen_position_id"
          source="id"
        >
          <Datagrid expand={<SkillEdit />} rowClick="expand">
            <ReferenceField
              label="Skill"
              source="test_gen_skill_id"
              reference="test_gen_skills"
            >
              <TextField source="skill" />
            </ReferenceField>
            <TextField source="priority" />
            <TextField source="question_count" label="Question count" />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <SkillCreate />
      </Tab>
      <Tab label="Position Names">
        <ReferenceManyField
          reference="test_gen_position_names"
          target="test_gen_position_id"
          addLabel={false}
        >
          <Datagrid>
            <TextField source="name" label="Position name" />
            <EditButton />
            <DeleteButton redirect={false} />
          </Datagrid>
        </ReferenceManyField>
        <PositionNameCreateButton />
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const SkillEdit = props => {
  const { record, save, saving, undoable, version } = useEditController({
    id: props.id,
    basePath: '/test_gen_position_test_gen_skill_jt',
    resource: 'test_gen_position_test_gen_skill_jt',
  });

  return (
    <SimpleForm
      record={record}
      save={save}
      saving={saving}
      undoable={undoable}
      version={version}
      redirect={false}
    >
      <SelectInput
        choices={[
          { id: 'primary', name: 'Primary' },
          { id: 'secondary', name: 'Secondary' },
          { id: 'open-ended', name: 'Open-ended' },
        ]}
        source="priority"
      />
      <FormDataConsumer>
        {({ formData }) => (
          <NumberInput
            initialValue={formData.priority === 'primary' ? 3 : 1}
            min={1}
            source="question_count"
            validate={[required(), number('This needs to be a number')]}
          />
        )}
      </FormDataConsumer>
    </SimpleForm>
  );
};

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  heading: {
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
}));

const SkillCreate = props => {
  const classes = useStyles();

  const { record, save, saving, undoable, version } = useCreateController({
    basePath: '/test_gen_position_test_gen_skill_jt',
    resource: 'test_gen_position_test_gen_skill_jt',
    record: { test_gen_position_id: props.record.id },
  });

  return (
    <Paper className={classes.paper}>
      <Typography variant="h6" className={classes.heading}>
        Add skill
      </Typography>
      <SimpleForm
        record={record}
        save={save}
        saving={saving}
        undoable={undoable}
        version={version}
        redirect={false}
      >
        <ReferenceInput
          label="Skill"
          source="test_gen_skill_id"
          reference="test_gen_skills"
          validate={required()}
          sort={{ field: 'skill', order: 'ASC' }}
          perPage={1000}
        >
          <SelectInput optionText="skill" />
        </ReferenceInput>
        <SelectInput
          choices={[
            { id: 'primary', name: 'Primary' },
            { id: 'secondary', name: 'Secondary' },
            { id: 'open-ended', name: 'Open-ended' },
          ]}
          source="priority"
        />
        <FormDataConsumer>
          {({ formData }) => (
            <>
              <NumberInput
                initialValue={formData.priority === 'primary' ? 3 : 1}
                min={1}
                source="question_count"
                validate={[required(), number('This needs to be a number')]}
              />
            </>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Paper>
  );
};
