import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff897a',
    },
    secondary: {
      main: '#2c1338',
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

export default theme;
