import React from 'react';
import {
  List,
  Datagrid,
  ReferenceManyField,
  SingleFieldList,
  ChipField,
  DeleteButton,
  BooleanField,
  Filter,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

export const PositionList = props => {
  return (
    <List title="Positions" filters={<PositionFilter />} {...props}>
      <Datagrid rowClick="show">
        <ReferenceManyField
          label="Position"
          reference="test_gen_position_names"
          target="test_gen_position_id"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceManyField>
        <BooleanField source="is_premium" label="Premium" />
        <BooleanField source="is_hidden" label="Hidden" />
        <DeleteButton undoable={false} />
      </Datagrid>
    </List>
  );
};

const PositionFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      label="Name"
      source="position_name_id"
      reference="test_gen_position_names"
      filterToQuery={searchText => ({ 'name.like': searchText })}
      alwaysOn
    >
      <AutocompleteInput optionText="name" optionValue="id" resettable />
    </ReferenceInput>
    <BooleanInput label="Hidden" source="is_hidden" />
    <BooleanInput label="Premium" source="is_premium" />
  </Filter>
);
