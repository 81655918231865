import React from 'react';

import {
  List,
  Datagrid,
  Filter,
  TextField,
  DateField,
  BooleanField,
  FunctionField,
  TextInput,
  ShowButton,
  BooleanInput,
} from 'react-admin';

export const CandidateList = props => (
  <List
    title="Candidates"
    filters={<CandidateFilter />}
    sort={{ field: 'created_at', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <DateField source="created_at" label="Created at" showTime />
      <EmailField label="E-mail" />
      <TextField source="name" label="Name" />
      <BooleanField label="Expert consent" source="expert_consent" />
      <TextField source="test_taker_test_version_count" label="Test versions" />
      <TextField source="job_opening_count" label="Job openings" />
      <TextField source="workspace_count" label="Workspaces" />
      <ShowButton />
    </Datagrid>
  </List>
);

const CandidateFilter = props => {
  return (
    <Filter {...props}>
      <TextInput label="Email" source="email" alwaysOn />
      <TextInput label="Name" source="name" />
      <TextInput source="test_taker_id" label="Test Taker ID" />
      <TextInput source="job_opening_id" label="Job opening ID" />
      <TextInput source="workspace_id" label="Workspace ID" />
      <TextInput source="candidate_id" label="Candidate ID" />
      <BooleanInput source="expert_consent" label="Expert consent" />
    </Filter>
  );
};

const EmailField = props => (
  <FunctionField
    source="email"
    render={record =>
      record.contact_email
        ? `${record.email}, ${record.contact_email}`
        : record.email
    }
    {...props}
  />
);
