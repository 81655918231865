import api from './api';

export default async function createAssessment(params) {
  try {
    const response = await api.post(`/support/assessment`, {
      skill_id: params.skill_id,
      social_meta_image_url: params.social_meta_image_url,
    });

    return response;
  } catch (error) {
    if (error.response.status === 500) {
      return error.response;
    }
  }
}
