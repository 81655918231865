import React from 'react';
import { useInput, Labeled } from 'react-admin';
import { sortBy } from 'lodash';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import ReorderIcon from '@material-ui/icons/Reorder';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ImageField } from './ImageField';
import {
  getEditableOptions,
  toggleOption,
  selectOption,
  updateOptionContent,
  moveOption,
  canRemoveOption,
  removeOption,
} from './state';

const parse = state =>
  state.map((option, index) => ({
    points: option.correct ? 1 : 0,
    content: option.content,
    order_weight: index,
  }));

const format = value =>
  sortBy(value, ['order_weight']).map(item => ({
    correct: item.points > 0,
    content: item.content,
  }));

const Item = ({ state, onChange, option, index, type }) => (
  <Draggable draggableId={`option-${index}`} index={index}>
    {provided => (
      <Grid
        container
        key={index}
        spacing={1}
        alignItems="center"
        ref={provided.innerRef}
        {...provided.draggableProps}
      >
        <Grid item>
          <IconButton
            disableRipple
            component="div"
            {...provided.dragHandleProps}
          >
            <ReorderIcon />
          </IconButton>
        </Grid>
        <Grid item>
          {type === 'multiple-choice' ? (
            <Checkbox
              checked={option.correct}
              onChange={event => {
                onChange(toggleOption(state, index, event.target.checked));
              }}
            />
          ) : (
            <Radio
              checked={option.correct}
              onChange={event => {
                if (event.target.checked) {
                  onChange(selectOption(state, index));
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={8}>
          {type === 'picture-question' ? (
            <ImageField
              value={option.content}
              onChange={value => {
                onChange(updateOptionContent(state, index, value));
              }}
            />
          ) : (
            <TextField
              multiline
              fullWidth
              value={option.content}
              onChange={event => {
                onChange(updateOptionContent(state, index, event.target.value));
              }}
            />
          )}
        </Grid>
        <Grid item>
          {canRemoveOption(state, index) ? (
            <IconButton
              aria-label="Remove"
              onClick={() => {
                onChange(removeOption(state, index));
              }}
            >
              <DeleteIcon />
            </IconButton>
          ) : null}
        </Grid>
      </Grid>
    )}
  </Draggable>
);

const ItemList = ({ state, onChange, type }) => (
  <Droppable droppableId="options">
    {provided => (
      <div ref={provided.innerRef} {...provided.droppableProps}>
        {getEditableOptions(state).map((option, index) => (
          <Item
            key={index}
            state={state}
            onChange={onChange}
            type={type}
            option={option}
            index={index}
          />
        ))}

        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export const OptionAnswerInput = ({ type, ...props }) => {
  const { input } = useInput({ ...props, parse, format });

  return (
    <DragDropContext
      onDragEnd={result => {
        if (result.destination == null) return;

        input.onChange(
          moveOption(input.value, result.source.index, result.destination.index)
        );
      }}
    >
      <Labeled label="Choices" fullWidth>
        <ItemList state={input.value} onChange={input.onChange} type={type} />
      </Labeled>
    </DragDropContext>
  );
};
