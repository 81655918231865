import { GET_LIST, GET_ONE, DELETE, DELETE_MANY, CREATE } from 'react-admin';
import api from './api';

export default async (type, params) => {
  switch (type) {
    case GET_LIST: {
      const { data } = await api.get('/admin/users');
      const accounts = data.admin_users || [];
      return { data: accounts, total: accounts.length };
    }

    case GET_ONE: {
      const { id } = params;
      const { data } = await api.get(`/admin/users/${id}`);
      return { data };
    }

    case DELETE: {
      await api.delete(`/admin/users/${params.id}`);
      return { data: params.previousData };
    }

    case DELETE_MANY: {
      await Promise.all(params.ids.map(id => api.delete(`/admin/users/${id}`)));

      return { data: params.ids };
    }

    case CREATE: {
      const data = { user_id: params.data.user_id };
      const response = await api.post('/admin/users', data);
      return { data: response.data };
    }

    default:
      throw new Error(`Unsupported request: ${type}`);
  }
};
