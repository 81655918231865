import React from 'react';
import { Editor, RichTextState } from '@togglhire/text-editor';
import { Labeled, useInput } from 'react-admin';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { uploadImage } from '../../../api/images';

export const MarkdownInput = props => {
  const classes = useStyles();

  const { input, meta, isRequired } = useInput(props);

  const [state, setState] = React.useState(RichTextState.create(input.value));

  React.useEffect(() => {
    if (state.markdown() !== input.value) {
      input.onChange(state.markdown());
    }
  }, [state, input]);

  return (
    <Labeled {...props} input={input} meta={meta} isRequired={isRequired}>
      <div
        className={classNames(classes.container, {
          [classes.fullWidth]: props.fullWidth,
        })}
      >
        <Editor state={state} onChange={setState} uploadImage={uploadImage} />
      </div>
    </Labeled>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    width: 450,
    padding: 10,
    borderRadius: theme.shape.borderRadius,
    borderColor: 'rgba(0, 0, 0, 0.23)',
    borderStyle: 'solid',
    borderWidth: 1,
  },
  fullWidth: {
    width: '100%',
  },
}));
