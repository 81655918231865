import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  DateField,
} from 'react-admin';

const AdminFeedbackList = props => (
  <List
    title="Admin feedback"
    sort={{ field: 'timestamp', order: 'DESC' }}
    bulkActionButtons={false}
    {...props}
  >
    <Datagrid>
      <DateField source="timestamp" label="Submitted" />
      <ReferenceField source="user_id" reference="users" link={false}>
        <TextField source="email" />
      </ReferenceField>
      <NumberField source="rating" />
      <TextField source="comment" />
    </Datagrid>
  </List>
);

export default AdminFeedbackList;
