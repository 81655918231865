import React from 'react';
import { Create, SaveButton, Toolbar } from 'react-admin';
import { QuestionForm } from './QuestionForm';

const CreateToolbar = props => (
  <Toolbar {...props}>
    <SaveButton label="save and show" redirect="show" submitOnEnter={true} />
    <SaveButton
      label="save and add"
      redirect={false}
      submitOnEnter={false}
      variant="flat"
    />
  </Toolbar>
);

export const QuestionCreate = props => (
  <Create {...props}>
    <QuestionForm toolbar={<CreateToolbar />} {...props} />
  </Create>
);
