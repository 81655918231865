import React from 'react';
import { Edit } from 'react-admin';
import SkillTitle from './SkillTitle';
import SkillForm from './SkillForm';

export const SkillEdit = props => (
  <Edit title={<SkillTitle />} {...props}>
    <SkillForm {...props} />
  </Edit>
);
