import React from 'react';
import { Create } from 'react-admin';
import { EmailTemplatesTitle } from './EmailTemplatesTitle';
import { EmailTemplatesForm } from './EmailTemplatesForm';

export const EmailTemplatesCreate = props => (
  <Create title={<EmailTemplatesTitle />} {...props}>
    <EmailTemplatesForm {...props} />
  </Create>
);
