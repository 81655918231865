import React from 'react';

const PercentageField = ({ source, record = {} }) => {
  const value = record[source];
  if (value == null) return null;

  return <span>{Math.floor(value)}%</span>;
};

export default PercentageField;
