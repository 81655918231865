import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

export const ChipList = props => {
  const classes = useStyles();
  return <div className={classes.container}>{props.children}</div>;
};

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    margin: theme.spacing(-0.5),
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));
