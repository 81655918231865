import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin';

const SkillForm = ({ hasList, hasEdit, hasShow, hasCreate, ...props }) => (
  <SimpleForm {...props} redirect="show">
    <TextInput source="skill" validate={required()} label="Name" />
    <ReferenceInput
      label="Type"
      source="test_gen_skill_type_id"
      reference="test_gen_skill_types"
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <BooleanInput source="is_hidden" label="Hidden" defaultValue={false} />
    <BooleanInput source="is_premium" label="Premium" defaultValue={false} />
    <BooleanInput
      source="archived_at"
      label="Archived"
      defaultValue={null}
      parse={inputValue => (inputValue ? new Date() : null)}
    />
  </SimpleForm>
);

export default SkillForm;
