import React from 'react';
import get from 'lodash/get';
import { Link } from '../common';

const LinkField = ({ record, source, content }) => (
  <Link href={get(record, source)}>{content || get(record, source)}</Link>
);

LinkField.defaultProps = {
  addLabel: true,
};

export default LinkField;
