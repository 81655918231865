import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  ShowButton,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { CandidatesButton } from './CandidatesButton';

export const JobOpeningList = props => (
  <List title="Job openings" filters={<JobOpeningFilter />} {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <ReferenceField source="workspace_id" reference="workspaces" link="show">
        <WorkspaceNameField />
      </ReferenceField>
      <TextField source="name" label="Name" />
      <TextField source="state" label="Status" />
      <ReferenceField
        source="id"
        reference="job_opening_stats"
        label="Candidates"
        link={false}
        sortable={false}
      >
        <NumberField source="test_takers" />
      </ReferenceField>
      <NumberField source="unlocked_test_takers" label="Unlocked candidates" />
      <CandidatesButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const JobOpeningFilter = props => (
  <Filter {...props}>
    <TextInput source="id" label="ID" />
    <TextInput source="slug" label="Slug" />
    <TextInput source="name.like" label="Name" />
    <SelectInput
      source="state"
      label="Status"
      choices={[
        { id: 'draft', name: 'Draft' },
        { id: 'published', name: 'Published' },
        { id: 'closed', name: 'Closed' },
      ]}
    />
    <ReferenceInput
      source="workspace_id"
      reference="workspaces"
      label="Workspace"
      filterToQuery={searchText => ({ name: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>

    <SelectInput
      label="Is not assessment"
      source="assesses_test_gen_skills_id.is"
      choices={[{ id: 'null', name: 'Is Not Assessment' }]}
      allowEmpty={true}
    />
    <SelectInput
      label="Is assessment"
      source="assesses_test_gen_skills_id.gte"
      choices={[{ id: 0, name: 'Is Assessment' }]}
      allowEmpty={true}
    />
  </Filter>
);

const WorkspaceNameField = withStyles({
  demo: {
    opacity: 0.2,
  },
})(({ record = {}, classes, className }) => {
  if (record.subscription_plan === 'demo-workspace') {
    return (
      <Typography
        component="span"
        variant="body1"
        className={classNames(className, classes.demo)}
      >
        Demo workspace
      </Typography>
    );
  } else {
    return (
      <Typography component="span" variant="body1" className={className}>
        {record.name}
      </Typography>
    );
  }
});
