import React from 'react';
import {
  Show,
  TabbedShowLayout,
  Tab,
  TextField,
  BooleanField,
  ReferenceManyField,
  Datagrid,
  ReferenceField,
  UrlField,
} from 'react-admin';
import { LinkToExpertQuestions } from '../common/LinkToExpertQuestions';

export const ExpertShow = props => (
  <Show title={<ExpertTitle />} {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" label="ID" />
        <ReferenceField
          label="Name"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="full_name" label="Full Name" />
        <ReferenceField
          label="Email"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <TextField source="email" />
        </ReferenceField>
        <ReferenceField
          label="Verified"
          source="user_id"
          reference="users"
          link={false}
          allowEmpty
        >
          <BooleanField source="is_email_verified" />
        </ReferenceField>
        <TextField source="billing_address" label="Billing Address" />
        <TextField source="bank_name" label="Bank Name" />
        <TextField source="iban" label="IBAN" />
        <TextField source="bic_swift" label="BIC/SWIFT" />
        <UrlField source="linkedin" label="LinkedIn" />
      </Tab>

      <Tab label="question batches">
        <ReferenceManyField
          reference="expert_question_batches"
          target="expert_id"
          addLabel={false}
        >
          <Datagrid>
            <TextField source="id" label="ID" />
            <ReferenceField
              source="expert_challenge_id"
              reference="expert_challenges"
              label="Challenge name"
              link={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField source="deadline" label="Deadline" />
            <TextField source="required_questions" label="Required Questions" />
            <TextField source="status" label="Status" />
            <LinkToExpertQuestions />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const ExpertTitle = ({ record }) => <span>User #{record.id}</span>;
