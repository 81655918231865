import workspaceDataProvider from './workspaces';
import candidateDataProvider from './candidates';
import userDataProvider from './users';
import jobOpeningDataProvider from './jobOpenings';
import jobOpeningStatsDataProvider from './jobOpeningStats';
import questionsDataProvider from './questions';
import notificationSettingsProvider from './notificationSettings';
import defaultDataProvider from './defaults';
import skills from './skills';
import positions from './positions';
import adminFeedback from './adminFeedback';
import candidateFeedback from './candidateFeedback';
import talentPool from './talentPool';
import submittedQuestions from './submittedQuestions';
import jobOpeningVisitors from './jobOpeningVisitors';
import workspaceInfo from './workspaceInfo';
import adminUsers from './adminUsers';
import positionSkills from './positionSkills';

export default async function(type, resource, params) {
  switch (resource) {
    case 'workspaces':
      return workspaceDataProvider(type, params);
    case 'candidates':
      return candidateDataProvider(type, params);
    case 'users':
      return userDataProvider(type, params);
    case 'job_openings':
      return jobOpeningDataProvider(type, params);
    case 'job_opening_stats':
      return jobOpeningStatsDataProvider(type, params);
    case 'questions':
      return questionsDataProvider(type, params);
    case 'notification_settings':
      return notificationSettingsProvider(type, params);
    case 'test_gen_skills':
      return skills(type, params);
    case 'test_gen_positions':
      return positions(type, params);
    case 'admin_feedback':
      return adminFeedback(type, params);
    case 'candidate_feedback':
      return candidateFeedback(type, params);
    case 'talent_pool':
      return talentPool(type, params);
    case 'submitted_questions':
      return submittedQuestions(type, params);
    case 'job_opening_visitors':
      return jobOpeningVisitors(type, params);
    case 'workspace_info':
      return workspaceInfo(type, params);
    case 'admin_users':
      return adminUsers(type, params);
    case 'test_gen_position_test_gen_skill_jt':
      return positionSkills(type, params);
    default:
      return defaultDataProvider(type, resource, params);
  }
}
