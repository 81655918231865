import axios from 'axios';
import store from 'store';
import configuration from '../configuration';
import getGoogleReCaptchaToken from '../utils/getGoogleReCaptchaToken';
import { getTogglAccountsLoginUrl } from '../utils/togglAccountsUrls';

const api = axios.create({
  baseURL: configuration.api.url,
  withCredentials:
    process.env.REACT_APP_SHARED_AUTH_ENABLED === 'true' ? true : false,
});

api.interceptors.request.use(async request => {
  if (request.captchaAction) {
    const token = await getGoogleReCaptchaToken(request.captchaAction);
    request.headers = {
      'captcha-token': token,
      ...request.headers,
    };
  }

  return request;
});

api.interceptors.request.use(request => {
  const authToken =
    process.env.REACT_APP_SHARED_AUTH_ENABLED === 'true'
      ? null
      : store.get('auth_token');

  if (authToken != null) {
    request.headers = {
      Authorization: `Bearer ${authToken}`,
      ...request.headers,
    };
  }

  return request;
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        if (process.env.REACT_APP_SHARED_AUTH_ENABLED === 'true') {
          window.location.assign(getTogglAccountsLoginUrl());
        }
      }
    }
  }
);

export default api;
