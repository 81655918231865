import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  required,
  minValue,
} from 'react-admin';

export const PositionCategoryForm = props => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" validate={required()} />
      <NumberInput
        source="order_weight"
        label="Order weight"
        defaultValue={0}
        validate={[required(), minValue(1)]}
      />
      <BooleanInput source="is_hidden" label="Hidden" defaultValue={false} />
    </SimpleForm>
  );
};
