import { CREATE, UPDATE } from 'react-admin';
import api from './api';
import defaultDataProvider from './defaults';
import { parseImage, serializeImage } from './images';
import { getJobOpeningStatus } from '../utils/jobOpenings';

const parse = data => {
  const result = { ...data };

  result.social_meta_image = parseImage(data.social_meta_image_url);
  delete result.social_meta_image_url;

  result.background_picture = parseImage(data.background_picture_url);
  delete result.background_picture_url;

  result.state = getJobOpeningStatus(result);

  return result;
};

const serialize = async data => {
  const result = { ...data };

  result.social_meta_image_url = await serializeImage(data.social_meta_image);
  delete result.social_meta_image;

  result.background_picture_url = await serializeImage(data.background_picture);
  delete result.background_picture;

  return result;
};

const defaultJobOpeningDataProvider = (type, params) => {
  return defaultDataProvider(type, 'job-openings', params, {
    parse,
    serialize,
  });
};

export default async (type, params) => {
  switch (type) {
    case CREATE:
      throw new Error('Cannot create a job opening');

    case UPDATE: {
      const { id, data, previousData } = params;

      if (data.state === 'published' && previousData.state === 'closed') {
        const result = await api.post(`/support/openings/${id}/publish`, {
          coupon_code: data.coupon,
        });

        if (result.status === 200) {
          return { data: { ...previousData, state: 'published' } };
        } else {
          return defaultJobOpeningDataProvider(type, params);
        }
      } else {
        return defaultJobOpeningDataProvider(type, params);
      }
    }

    default:
      return defaultJobOpeningDataProvider(type, params);
  }
};
