import React from 'react';
import chooseFiles from 'choose-files';
import Button from '@material-ui/core/Button';

export const ImageField = ({ value, onChange }) => {
  const selectFile = () => {
    chooseFiles({ multiple: false }, ([file]) => {
      onChange(file);
    });
  };

  if (value) {
    return <Button onClick={selectFile}>Reupload</Button>;
  } else {
    return <Button onClick={selectFile}>Upload</Button>;
  }
};
