import {
  GET_ONE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
} from 'react-admin';
import { identity, omitBy, isEmpty, isPlainObject } from 'lodash';
import flatten from 'flat';
import api from './api';
import { getCommonParams } from './utils';

export default async function(
  type,
  resource,
  params,
  { parse = identity, serialize = identity } = {}
) {
  switch (type) {
    case GET_ONE: {
      const response = await api.get(`/support/db/${resource}/${params.id}`);
      return { data: parse(response.data) };
    }

    case GET_LIST: {
      const { pagination, filter, sort } = params;

      const query = {
        ...getCommonParams(pagination, sort),
        ...omitBy(flatten(filter), isEmptyObject),
      };

      const response = await api.get(`/support/db/${resource}`, {
        params: query,
      });

      const { total, data } = response.data;

      return {
        data: data ? data.map(parse) : [],
        total: total,
      };
    }

    case GET_MANY: {
      if (params.ids.length === 0) return { data: [], total: 0 };

      const response = await api.get(`/support/db/${resource}`, {
        params: {
          offset: 0,
          'id.in': params.ids.join(),
        },
      });

      const { data } = response.data;

      return {
        data: data ? data.map(parse) : [],
      };
    }

    case GET_MANY_REFERENCE: {
      const { target, id, pagination, sort } = params;

      const response = await api.get(`/support/db/${resource}`, {
        params: {
          ...getCommonParams(pagination, sort),
          [target]: id,
        },
      });

      const { total, data } = response.data;

      return {
        data: data ? data.map(parse) : [],
        total: total,
      };
    }

    case CREATE: {
      const response = await api.post(
        `/support/db/${resource}`,
        await serialize(params.data)
      );

      return { data: parse(response.data) };
    }

    case UPDATE: {
      const response = await api.patch(
        `/support/db/${resource}/${params.id}`,
        await serialize(params.data)
      );

      return { data: parse(response.data) };
    }

    case DELETE: {
      await api.delete(`/support/db/${resource}/${params.id}`);

      return { data: params.previousData };
    }

    case DELETE_MANY: {
      await Promise.all(
        params.ids.map(id => api.delete(`/support/db/${resource}/${id}`))
      );

      return { data: params.ids };
    }

    default:
      throw new Error(`Unsupported request: ${type}`);
  }
}

const isEmptyObject = value => isPlainObject(value) && isEmpty(value);
