import React from 'react';
import {
  List,
  Datagrid,
  SingleFieldList,
  TextField,
  BooleanField,
  ReferenceArrayField,
  ChipField,
  Filter,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  BooleanInput,
} from 'react-admin';
import CountriesInput from './CountriesInput';
import RegionInput from './RegionInput';

const TalentPoolList = props => (
  <List
    title="Talent pool"
    bulkActionButtons={false}
    filters={<ListFilter />}
    {...props}
  >
    <Datagrid rowClick="show">
      <TextField source="full_name" label="Name" />
      <TextField source="contact_email" label="Email" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />
      <BooleanField source="is_willing_to_work_remotely" label="Remote" />
      <ReferenceArrayField
        label="Skills"
        reference="test_gen_skills"
        source="test_gen_skill_ids"
      >
        <SingleFieldList link="show">
          <ChipField source="skill" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="Job openings"
        reference="job_openings"
        source="job_opening_ids"
      >
        <SingleFieldList link="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </List>
);

const ListFilter = props => (
  <Filter {...props}>
    <TextInput label="Job opening" source="job_opening_name" />
    <CountriesInput label="Countries" source="countries" />
    <RegionInput label="Region" source="region" />
    <TextInput label="Email" source="email" />
    <BooleanInput label="Remote" source="remote" />
    <ReferenceArrayInput
      label="Skills&nbsp;(AND)"
      source="skills_all"
      reference="test_gen_skills"
      perPage={100}
    >
      <SelectArrayInput optionText="skill" />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="Skills&nbsp;(OR)"
      source="skills_some"
      reference="test_gen_skills"
      perPage={100}
    >
      <SelectArrayInput optionText="skill" />
    </ReferenceArrayInput>
  </Filter>
);

export default TalentPoolList;
