const planCodes = [
  'free-2021',
  'free-exp-2023',
  'free-v2-2023',
  'standard-1-month',
  'standard-3-months',
  'premium-1-month',
  'premium-3-months',
  'basic-12m-2021',
  'basic-12m-exp-2023',
  'basic-12m-v2-2023',
  'premium-12m-60c-2023',
  'premium-1m-2021',
  'premium-12m-2021',
  'premium-1m-exp-2023',
  'premium-12m-exp-2023',
  'premium-1m-v2-2023',
  'premium-12m-v2-2023',
  'business-1m-2021',
  'business-12m-2021',
  'business-1m-exp-2023',
  'business-12m-exp-2023',
  'business-1m-v2-2023',
  'business-12m-v2-2023',
  'unlimited-1m-2021',
  'unlimited-12m-2021',
  'demo-workspace',
  'basic-12m-basicexperiment-oct22',
  'free-basicexperiment-oct22',
];

export const PLANS = planCodes.map(plan => ({ id: plan, name: plan }));
