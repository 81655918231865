import store from 'store';
import { authenticate, fetchMe } from './authentication';
import { logout } from './togglAccounts/logout';
import { getTogglAccountsLoginUrl } from '../utils/togglAccountsUrls';

const authProvider = {
  login: async ({ username, password }) => {
    if (process.env.REACT_APP_SHARED_AUTH_ENABLED !== 'true') {
      const token = await authenticate(username, password);
      store.set('auth_token', token);
    }
  },
  logout: async () => {
    if (process.env.REACT_APP_SHARED_AUTH_ENABLED === 'true') {
      await logout();
      window.location.assign(getTogglAccountsLoginUrl());
    } else {
      store.remove('auth_token');
    }
  },
  getPermissions: () => {
    // Required for the authentication to work
    return Promise.resolve();
  },
  checkError: async error => {
    if (error.response) {
      const { status } = error.response;

      if (status === 401 || status === 403) {
        store.remove('auth_token');
        throw new Error('Unauthenticated');
      }
    }
  },
  checkAuth: async () => {
    if (process.env.REACT_APP_SHARED_AUTH_ENABLED !== 'true') {
      const token = store.get('auth_token');

      if (token == null) {
        throw new Error('Unauthenticated');
      }
    }
  },
  getIdentity: async () => {
    const me = await fetchMe();

    return {
      id: me.id,
      fullName: me.name,
      avatar: me.profilePictureUrl,
    };
  },
};

export default authProvider;
