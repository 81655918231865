import React from 'react';
import {
  List,
  Datagrid,
  SimpleShowLayout,
  Filter,
  TextField,
  ReferenceField,
  DateField,
  FunctionField,
  BooleanInput,
  SelectInput,
} from 'react-admin';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import { truncate } from 'lodash';
import { PercentageField } from '../fields';

const CandidateFeedbackList = props => (
  <List
    title="Candidate feedback"
    sort={{ field: 'created_at', order: 'DESC' }}
    bulkActionButtons={false}
    filters={<ListFilter />}
    {...props}
  >
    <Datagrid rowStyle={getRowStyle} expand={<ExpandPanel />} rowClick="expand">
      <DateField source="created_at" label="Submitted" />
      <ReferenceField
        source="test_taker_id"
        reference="candidates"
        link="show"
        label="Candidate"
      >
        <TextField source="email" />
      </ReferenceField>
      <TextField source="area" />
      <FunctionField
        render={record => truncate(record.feedback, { length: 50 })}
      />
    </Datagrid>
  </List>
);

const ExpandPanel = props => (
  <SimpleShowLayout {...props}>
    <ReferenceField
      source="test_taker_id"
      reference="candidates"
      link={false}
      label="Score"
    >
      <PercentageField source="overall_score_as_percents" label="Score" />
    </ReferenceField>
    <TextField source="feedback" />
  </SimpleShowLayout>
);

const ListFilter = props => (
  <Filter {...props}>
    <BooleanInput label="Positive" source="is_positive" />
    <SelectInput
      label="Area"
      source="area"
      choices={categories.map(category => ({ id: category, name: category }))}
    />
  </Filter>
);
const categories = [
  'That a test is preferred over resumes',
  'Test questions',
  'Getting instant feedback',
  'Overall look and feel',
  'Other',
];

const getRowStyle = record => ({
  backgroundColor: record.is_positive ? green['50'] : red['50'],
});

export default CandidateFeedbackList;
