import React from 'react';
import {
  List,
  Filter,
  TextInput,
  SelectInput,
  NullableBooleanInput,
  BooleanInput,
  NumberInput,
} from 'react-admin';
import { PLANS } from '../../utils/subscriptions';
import { WorkspaceExportButton } from './WorkspaceExportButton';
import { WorkspaceGrid } from './WorkspaceGrid';

export const WorkspaceList = props => (
  <List
    title="Workspaces"
    filters={<WorkspaceFilter />}
    bulkActionButtons={<WorkspaceBulkActions />}
    sort={{ field: 'created_at', order: 'DESC' }}
    filterDefaultValues={{ 'is-owner-email-verified': true }}
    perPage={25}
    {...props}
  >
    <WorkspaceGrid />
  </List>
);

const WorkspaceFilter = props => (
  <Filter {...props}>
    <NullableBooleanInput
      label="Email verified"
      source="is-owner-email-verified"
      alwaysOn
    />
    <TextInput label="Search by name" source="name" alwaysOn />
    <TextInput label="Search by ID" source="id" alwaysOn />
    <NumberInput
      label="Min. candidates"
      source="min-test-takers"
      defaultValue={5}
    />
    <NumberInput
      label="Max. candidates"
      source="max-test-takers"
      defaultValue={5}
    />
    <BooleanInput
      label="Signed up with LinkedIn"
      source="signed-up-with-linkedin"
      defaultValue={true}
    />
    <SelectInput
      label="Subscription plan"
      source="subscription-plan"
      choices={PLANS}
    />
    <NumberInput
      label="Min. time spent (minutes)"
      source="min-usage-time"
      defaultValue={10 * 60}
      format={value => (value != null ? Math.floor(value / 60) : 0)}
      parse={value => (value ? parseInt(value, 10) * 60 : null)}
    />
    <NumberInput label="Min. sessions" source="min-sessions" defaultValue={3} />
    <NumberInput
      label="Min. video intros sent"
      source="min-video-intros-sent"
      defaultValue={1}
    />
    <NumberInput
      label="Max. video intros sent"
      source="max-video-intros-sent"
      defaultValue={10}
    />
    <NumberInput
      label="Min. video intros completed"
      source="min-video-intros-completed"
      defaultValue={1}
    />
    <NumberInput
      label="Max. video intros completed"
      source="max-video-intros-completed"
      defaultValue={10}
    />
    <NumberInput
      label="Min. video intros not sent"
      source="min-video-intros-not-completed"
      defaultValue={1}
    />
    <NumberInput
      label="Max. video intros not sent"
      source="max-video-intros-not-completed"
      defaultValue={10}
    />
    <SelectInput
      label="Priority"
      source="priority"
      choices={[
        { id: '1', name: '1' },
        { id: '2', name: '2' },
        { id: '3', name: '3' },
        { id: '4', name: '4' },
      ]}
    />
  </Filter>
);

const WorkspaceBulkActions = props => (
  <>
    <WorkspaceExportButton {...props} />
  </>
);
