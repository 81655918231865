import { takeEvery, call, all } from 'redux-saga/effects';
import download from 'downloadjs';
import configuration from './configuration';
import api from './api/api';

export function* supportAuthSaga() {
  yield all([
    yield takeEvery('SUPPORT_LOG_IN', handleSupportLogIn),
    yield takeEvery('EXPORT_CANDIDATE', handleExportCandidate),
  ]);
}

function* handleSupportLogIn(action) {
  const { workspaceId } = action.payload;

  const response = yield call(
    api.post,
    `/support/workspaces/${workspaceId}/token`
  );

  const token = response.data.access_token;
  const url = `${configuration.admin.url}/support-auth?token=${token}`;
  window.location.href = url;
}

function* handleExportCandidate(action) {
  const { candidateId, shadowContactInfo } = action.payload;

  const response = yield call(
    api.get,
    `/support/test-takers/${candidateId}/export.pdf`,
    {
      params: { 'shadow-contact-info': shadowContactInfo ? 'true' : 'false' },
      responseType: 'blob',
    }
  );

  download(response.data, `candidate-${candidateId}.pdf`, 'application/pdf');
}
