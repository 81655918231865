import { isEmpty, last } from 'lodash';
import produce from 'immer';

const emptyOption = {
  correct: false,
  content: '',
};

export const getEditableOptions = (state = []) => {
  if (isEmpty(state)) {
    return [emptyOption];
  }

  const lastOption = last(state);

  if (lastOption.content) {
    return [...state, emptyOption];
  } else {
    return state;
  }
};

export const toggleOption = (state = [], index, correct) => {
  return produce(state, draft => {
    if (index === draft.length) {
      draft.push({ ...emptyOption, correct });
    } else {
      draft[index].correct = correct;
    }
  });
};

export const selectOption = (state = [], index) => {
  return produce(state, draft => {
    if (index === draft.length) {
      draft.push({ ...emptyOption });
    }

    for (let i = 0; i < draft.length; i++) {
      draft[i].correct = i === index;
    }
  });
};

export const updateOptionContent = (state = [], index, content) => {
  return produce(state, draft => {
    if (index === draft.length) {
      draft.push({ ...emptyOption, content });
    } else {
      draft[index].content = content;
    }
  });
};

export const moveOption = (state = [], fromIndex, toIndex) => {
  return produce(state, draft => {
    const [option] = draft.splice(fromIndex, 1);
    draft.splice(toIndex, 0, option);
  });
};

export const canRemoveOption = (state = [], index) => {
  return index < state.length;
};

export const removeOption = (state = [], index) => {
  return produce(state, draft => {
    draft.splice(index, 1);
  });
};
