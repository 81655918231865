import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-admin';
import LockIcon from '@material-ui/icons/Lock';
import { supportLogIn } from '../../actions';

export const LogInButton = connect(
  null,
  { supportLogIn }
)(({ record, supportLogIn }) => (
  <Button
    size="small"
    color="primary"
    label="Log in"
    onClick={event => {
      event.stopPropagation();
      supportLogIn(record.id);
    }}
  >
    <LockIcon />
  </Button>
));
