import React from 'react';
import { connect } from 'react-redux';
import PublishIcon from '@material-ui/icons/Publish';
import UndoIcon from '@material-ui/icons/Undo';
import { Button } from 'react-admin';
import { updateUserStatus } from '../../actions';

const UserStatusButton = connect(
  null,
  { updateUserStatus }
)(({ record = {}, basePath, updateUserStatus, ...props }) => (
  <Button
    size="small"
    color="primary"
    label={record.is_suspended ? 'Activate' : 'Suspend'}
    onClick={e => {
      e.stopPropagation();
      updateUserStatus(record, !record.is_suspended);
    }}
    {...props}
  >
    {record.is_suspended ? <PublishIcon /> : <UndoIcon />}
  </Button>
));

export default UserStatusButton;
